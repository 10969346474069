import React, { useState } from 'react';
import { CircleLoading, Icon, RadioButton, Text } from 'new-ui';

import { getText } from '../../../../../i18n';

import { MOBX_STORES } from '../../../../bi/context/stores';

import { useStores } from '../../../../bi/context';

import Event from '../../../../bi/services/events';

import { LargeCity } from './components/LargeCity';
import { ForgeinCity } from './components/ForgeinCity';
import { CompleteCalculator } from './components/CompetePage';

import styles from './styles/index.module.css';

const DIRECTION = {
  LARGE_CITY: 'RussiaMajor',
  OTHER_CITY: 'RussiaMinor',
  FORGEIN_CITY: 'forgein',
};

const LABELS = {
  TITLE: getText('event:calculator.title'),
  DATA: {
    TITLE: getText('event:calculator.data.title'),
    SUB_TITLE: getText('event:calculator.data.subtitle'),
    CHECKBOX: {
      LARGE: getText('event:calculator.data.type.large'),
      OTHER: getText('event:calculator.data.type.other'),
      FORGEIN: getText('event:calculator.data.type.forgein'),
    },
  },
};

interface ICalculatorMice {
  eventService: Event,
  setShowCalculator(): void,
}

const CalculatorMice = ({ eventService: { calculatorSend }, setShowCalculator }: ICalculatorMice) => {
  const { eventStore: { calculator: { value, isLoading, isError } } } = useStores([MOBX_STORES.EVENT_STORE]);

  const [direction, setDirection] = useState<string>(DIRECTION.LARGE_CITY);
  const [isComplete, setIsComplete] = useState<boolean>(false);

  const renderLargeCity = () => {
    if (direction === DIRECTION.FORGEIN_CITY) {
      return null;
    }

    const onSetIsComplete = () => setIsComplete(prev => !prev);

    return (
      <div className={ styles.wrapper_sub_large }>
        <LargeCity
          direction={ direction }
          calculatorSend={ calculatorSend }
          setShowCalculator={ setShowCalculator }
          setIsCompete={ onSetIsComplete }
        />
      </div>
    );
  };

  const renderForgeinCity = () => {
    if (direction !== DIRECTION.FORGEIN_CITY) {
      return null;
    }

    return (
      <div className={ styles.wrapper_sub_large }>
        <ForgeinCity/>
      </div>
    );
  };

  const renderCity = () => (
    <div className={ styles.wrapper }>
      <Text className={ styles.title } type='bold_32'>
        { LABELS.TITLE }
      </Text>
      <div className={ styles.wrapper_data }>
        <Text className={ styles.title } type='bold_20'>
          { LABELS.DATA.TITLE }
        </Text>
        <Text type='NORMAL_16'>
          { LABELS.DATA.SUB_TITLE }
        </Text>
        <div className={ styles.wrapper_checkbox }>
          <RadioButton
            value={ DIRECTION.LARGE_CITY }
            checked={ direction === DIRECTION.LARGE_CITY }
            onChange={ () => setDirection(DIRECTION.LARGE_CITY) }
          >
            <div className={ styles.wrapper_large }>
              <Text type='NORMAL_16'>
                { LABELS.DATA.CHECKBOX.LARGE }
              </Text>
              <Icon className={ styles.icon } type='question'/>
            </div>
          </RadioButton>
          <RadioButton
            value={ DIRECTION.OTHER_CITY }
            checked={ direction === DIRECTION.OTHER_CITY }
            onChange={ () => setDirection(DIRECTION.OTHER_CITY) }
          >
            <Text type='NORMAL_16'>
              { LABELS.DATA.CHECKBOX.OTHER }
            </Text>
          </RadioButton>
          <RadioButton
            value={ DIRECTION.FORGEIN_CITY }
            checked={ direction === DIRECTION.FORGEIN_CITY }
            onChange={ () => setDirection(DIRECTION.FORGEIN_CITY) }
          >
            <Text type='NORMAL_16'>
              { LABELS.DATA.CHECKBOX.FORGEIN }
            </Text>
          </RadioButton>
        </div>
      </div>
      { renderLargeCity() }
      { renderForgeinCity() }
    </div>
  );

  const renderComplete = () => (
    <CompleteCalculator value={ value }/>
  );

  const renderLoading = () => (
    <CircleLoading size={ 500 }/>
  );

  if (isLoading) {
    return renderLoading();
  }

  return !isComplete && !isError ? renderComplete() : renderCity();
};

export { CalculatorMice };
