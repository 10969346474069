import { observer } from 'mobx-react';
import React, { Component } from 'react';
import * as queryString from 'query-string';
import { PageLoader, BackLink } from 'new-ui';

import { getText } from '../../../i18n';

import { withStores } from '../../bi/context';
import { MOBX_STORES } from '../../bi/context/stores';

import { TrainsSearchMenu } from '../../components/Menu/TrainSearchMenu';
import { TrainPrimaryInfoBlock } from './components/PrimaryInfoBlock';

import FAVORITESACTION from '../../bi/constants/favorites';
import ROUTES from '../../bi/constants/routes';
import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';

import { prepareUrl } from '../../bi/utils/train';
import textTransformation from '../../bi/utils/textTransformation';
import { MainAnalytic } from '../../bi/utils/analytics';
import { parseSearchString } from '../../bi/utils/convertSearchParams';
import { isSmartAgent } from '../../bi/utils/env';

import { ICarItem, IPlacesItem, ISavedTicket, ITrain } from '../../bi/services/trains/store/types';
import { IPlace, IRequestAddToCartProps, ITrainResultPageState, TrainResultPageType } from './types';

import styles from './index.module.css';

const LABELS = {
  TRAIN_SEARCH: getText('trains:result.loading'),
  TRAIN_SEARCH_BACK: getText('trains:result.loadingBack'),
  CAR_SEARCH: getText('trains:result.loadingCar'),
  PUSH: {
    SUCCESS_NOTE: (depart: string, arrive: string, number: string) =>
      getText('trains:result.notifications.note.success', { depart, arrive, number }),
    FAILED_NOTE: (depart: string, arrive: string, number: string) =>
      getText('trains:result.notifications.note.failed', { depart, arrive, number }),
    SUCCESS_CART: (depart: string, arrive: string, number: string) =>
      getText('trains:result.notifications.cart.success', { depart, arrive, number }),
    FAILED_CART: (depart: string, arrive: string, number: string) =>
      getText('trains:result.notifications.cart.failed', { depart, arrive, number }),
    REMOVE_FAVORITE: (number: string) =>
      getText('trains:result.notifications.favorite.remove', { number }),
    ADD_FAVORITE: (depart: string, arrive: string, number: string) =>
      getText('trains:result.notifications.favorite.add', { depart, arrive, number }),
  },
  TO_CHOOSE_TRAIN: getText('trains:result.toChooseTrain'),
  TO_CHOOSE_TRAINS: getText('trains:result.toChooseTrains'),
  TO_CHOOSE_CAR: getText('trains:result.toChooseCar'),
  TOOLTIP_BACK: getText('trains:result.tooltipBack'),
};

@withStores([
  MOBX_STORES.TRAIN_SEARCH,
  MOBX_STORES.TRAIN,
  MOBX_STORES.TRAIN_SAVED_TICKET,
  MOBX_STORES.TRAIN_SAVED_TICKETS,
])
@observer
class TrainResultPage extends Component<TrainResultPageType, ITrainResultPageState> {
  static defaultProps = {
    isCarPage: false,
    qaAttrCartNotification: '',
  };

  idRequest: number;
  idRequestItem: number;

  constructor(props: TrainResultPageType) {
    super(props);

    const { requestsService } = props;
    const { request, requestItem } = requestsService.get();

    const parsedSearchString = parseSearchString(location.search);
    const isBack = parsedSearchString && parsedSearchString.is_back;

    this.idRequest = request.Id;
    this.idRequestItem = requestItem.Id;

    this.state = {
      isAddToCartBack: isBack,
      tripId: (parsedSearchString?.tripId as string) || '',
    };
  }

  componentDidMount() {
    const { trainsService, match: { params: { trainId, searchId } } } = this.props;

    trainsService.getTrainById(trainId, searchId);
  }

  getImmediateSearch = (isSavedTickets = false) => {
    const {
      history,
      trainsService: {
        setFirstSearch,
        setImmediateSearch,
        setDateBack,
        setSavedTicket,
        clearSavedTicketsWithTransfer,
      },
      stores: {
        trainSearchStore: { dateBack },
      },
    } = this.props;

    if (dateBack) {
      setFirstSearch(true);
      setImmediateSearch(true);
    }

    setDateBack(null);
    setSavedTicket(null);

    if (isSavedTickets) {
      clearSavedTicketsWithTransfer();
    }

    return history.push(ROUTES.SEARCH.TRAIN_RESULTS);
  };

  handleAddToCart = async (car: ICarItem, places: IPlacesItem[], totalPrice: number) => {
    const {
      trainsService,
      trainsService: { addToCartTwo },
      match: {
        params: {
          trainId,
          searchId,
        },
      },
      history,
      stores: {
        trainSearchStore: { dateBack },
        trainStore: { train },
        trainSavedTicketStore: { savedTicket },
        trainSavedTicketsStore: { savedTickets },
      },
    } = this.props;
    const { tripId } = this.state;

    const idRequestItem = this.idRequestItem > 0 ? this.idRequestItem : null;

    if (dateBack && !savedTicket && !savedTickets.length) {
      trainsService.setSavedTicket({ car, places, totalPrice, train, searchId, travellers: places.length, tripId });
      trainsService.setImmediateSearch(true);

      return history.push(ROUTES.SEARCH.TRAIN_RESULTS);
    }

    if (!savedTicket && !savedTickets.length) {
      return this.completeRequestAddToCart({ car, places, totalPrice, train, idRequestItem, trainId, searchId })
        .then(url => history.push(url as string));
    }

    if (savedTickets.length) {
      const requests: Promise<string | void>[] = [];

      const req = (ticket: ISavedTicket) => {
        const promise = this.completeRequestAddToCart({
          car: ticket.car,
          places: ticket.places,
          totalPrice: ticket.totalPrice,
          train: ticket.train,
          trainId: ticket.TrainId,
          searchId: ticket.searchId,
          fn: addToCartTwo,
          idRequestItem,
          tripId,
        });
        requests.push(promise);

        return promise;
      };

      // временное решение, гонка на фронте, потом на бэк уедет
      const firstReq = req(savedTickets[0]);
      await firstReq;

      if (savedTickets.length > 1) {
        const secondReq = req(savedTickets[1]);
        await secondReq;
      }

      const arrSavedTickets = [...savedTickets.slice(2)];

      if (arrSavedTickets.length) {
        arrSavedTickets.forEach(ticket => req(ticket));
      }

      return Promise.all(requests).then(() => this.getImmediateSearch(true));
    }

    if (!savedTicket) {
      return null;
    }

    return this.completeRequestAddToCart({
      car: savedTicket.car,
      places: savedTicket.places,
      totalPrice: savedTicket.totalPrice,
      train: savedTicket.train,
      idRequestItem: savedTicket.idRequestItem,
      trainId: savedTicket.train.TrainId,
      searchId: savedTicket.searchId,
    })
      .then(() => this.completeRequestAddToCart({
        car,
        places,
        totalPrice,
        train,
        idRequestItem,
        trainId,
        searchId,
        tripId,
      }))
      .then(() => this.getImmediateSearch())
      .then(this.props.orderService.tripCache.clear);
  };

  handleAddToNote = (car: ICarItem, places: IPlace[], totalPrice: number) => {
    const {
      trainsService,
      notificationService,
      history,
      match: {
        params: {
          trainId,
          searchId,
        },
      },
      stores: {
        trainStore: {
          train: {
            StationDepart,
            StationArrive,
            Number: trainNumber,
            Number2: trainNumber2,
          },
        },
      },
      qaAttrs,
    } = this.props;
    const { tripId } = this.state;
    const departText = textTransformation(StationDepart);
    const arriveText = textTransformation(StationArrive);

    const trainNumberResult = trainNumber2 || trainNumber || '';

    return trainsService.addToNote({ TrainId: trainId, SearchId: searchId }, car, places, totalPrice, tripId)
      .then(() => {
        notificationService.send({
          message: LABELS.PUSH.SUCCESS_NOTE(departText, arriveText, trainNumberResult),
          level: 'success',
          onClick: () => history.push(ROUTES.NOTE.MAIN),
          qaAttr: qaAttrs.notifications.note,
        });

        history.push(prepareUrl({ searchId, trainId, requestId: this.idRequest, tripId, isBack: true }));
      })
      .catch(() => {
        notificationService.send({
          message: LABELS.PUSH.FAILED_NOTE(departText, arriveText, trainNumberResult),
          level: 'error',
        });
      });
  };

  completeRequestAddToCart = async ({
    car,
    places,
    totalPrice,
    train,
    idRequestItem,
    trainId,
    searchId,
    fn = null,
    tripId,
  }: IRequestAddToCartProps) => {
    const {
      trainsService,
      notificationService,
      history,
      qaAttrCartNotification,
      orderService: { tripCache: { tripId: tripIdCache } },
    } = this.props;

    const tripIdAddtoCart = tripIdCache || tripId;

    const {
      TrainId,
      StationDepart,
      StationArrive,
      Number: trainNumber,
      Number2: trainNumber2,
    } = train;

    const trainNumberResult = trainNumber2 || trainNumber || '';

    const addToCart = fn || trainsService.addToCart;

    const promise = () => addToCart({ TrainId, SearchId: searchId } as ITrain, car, places, totalPrice, idRequestItem);

    const departText = textTransformation(StationDepart);
    const arriveText = textTransformation(StationArrive);

    return promise().then(() => {
      notificationService.send({
        message: LABELS.PUSH.SUCCESS_CART(departText, arriveText, trainNumberResult),
        level: 'success',
        onClick: () => history.push(ROUTES.CART.MAIN),
        qaAttr: qaAttrCartNotification,
      });

      return prepareUrl({ searchId, trainId, requestId: this.idRequest, tripId: String(tripIdAddtoCart), isBack: true });
    })
      .catch(() => {
        notificationService.send({
          message: LABELS.PUSH.FAILED_CART(departText, arriveText, trainNumberResult),
          level: 'error',
        });
      });
  };

  handleFavoriteToggle = (action: string) => {
    const {
      favoritesService,
      trainsService,
      notificationService,
      history,
      stores: {
        trainStore: {
          train: {
            Number: trainNumber,
            FavoriteId,
            StationCodeFrom = null,
            StationCodeTo = null,
            ClassificatorId,
            ArrivalDate,
            ArrivalDateLocal,
            DepartureDate,
            DepartureDateLocal,
            StationDepart,
            StationArrive,
            TrainId,
            FirmName,
            Number2: trainNumber2,
            TravelTime,
          },
        },
      },
    } = this.props;

    const trainNumberResult = trainNumber2 || trainNumber || '';

    if (action === FAVORITESACTION.REMOVE) {
      return favoritesService.removeItem(FavoriteId)
        .then(() => {
          notificationService.send({
            message: LABELS.PUSH.REMOVE_FAVORITE(trainNumberResult),
            level: 'success',
            qaAttr: QA_ATTRIBUTES.search.train.notification.removeFavorite,
          });

          trainsService.changeFavoriteStatus(ClassificatorId, '');
          trainsService.setTrainFavorite(null);
        });
    }

    return trainsService.addToFavorite({
      ArrivalDate,
      ArrivalDateLocal,
      DepartureDate,
      DepartureDateLocal,
      TrainId,
      ProviderName: '',
      StationFrom: StationDepart,
      StationTo: StationArrive,
      TrainName: FirmName,
      TrainNumber: trainNumber,
      TrainNumberLocal: trainNumber2,
      TravelTime,
      StationCodeFrom,
      StationCodeTo,
    }).then((favoriteId: string) => {
      const depart = textTransformation(StationDepart);
      const arrive = textTransformation(StationArrive);

      notificationService.send({
        message: LABELS.PUSH.ADD_FAVORITE(depart, arrive, trainNumberResult),
        level: 'success',
        onClick: () => history.push(ROUTES.FAVORITES),
      });

      trainsService.setTrainFavorite(favoriteId);

      MainAnalytic.send(MainAnalytic.CATEGORY.TRAINS, MainAnalytic.ACTIONS.TRAINS.ADDTOFAVORITES, {
        label: MainAnalytic.LABELS.TRAINS.TRAININFORMATION,
        value: parseInt(trainNumber, 10),
      });
    });
  };

  handleSearch = () => {
    const { trainsService, stores: { trainSearchStore: isValid }, history } = this.props;

    if (isValid) {
      history.push({
        pathname: ROUTES.SEARCH.TRAIN_RESULTS,
        search: queryString.stringify(trainsService.mapStateToSearchObject()),
      });
    }
  };

  handleGoBack = () => {
    const {
      history,
      match: { params: { number } },
      trainsService,
    } = this.props;

    if (!number) {
      trainsService.clearAfterGoBack();
    }

    const { url } = this.getBackLinkInfo();

    return history.push(url);
  };

  getBackLinkInfo = () => {
    const {
      match: {
        params: {
          trainId,
          searchId,
          number,
        },
      },
      stores: {
        trainSavedTicketsStore: { isTicketWithTransfer },
      },
    } = this.props;
    const { tripId } = this.state;

    const numberedUrl = prepareUrl({ searchId, trainId, requestId: this.idRequest, tripId, isBack: true });
    const requestedUrl = this.idRequest > 0 ? `${ROUTES.SEARCH.TRAIN_RESULTS}?is_request=true` : ROUTES.SEARCH.TRAIN_RESULTS;

    const url = number ? numberedUrl : requestedUrl;
    const backTrain = isTicketWithTransfer ? LABELS.TO_CHOOSE_TRAINS : LABELS.TO_CHOOSE_TRAIN;
    const name = number ? LABELS.TO_CHOOSE_CAR : backTrain;

    return {
      url,
      name,
    };
  };

  getArrivalDate = () => {
    const { match: { params: { number } }, stores: { trainStore: { train: { Cars, ArrivalDate } } } } = this.props;

    if (!number) return ArrivalDate;

    const selectedCar = Cars.find(({ Number }) => Number === number);

    if (!selectedCar) return ArrivalDate;

    const tariff = selectedCar.Tariffs.find(({ Modificator }) => !!Modificator);

    if (!tariff || !tariff.Modificator?.Arrival.ArrivalDate) return ArrivalDate;

    return tariff.Modificator.Arrival.ArrivalDate;
  };

  renderBackLink = () => {
    const {
      match: {
        params: { number },
      },
      stores: {
        trainSavedTicketsStore: { notAllSavedTickets },
      },
      qaAttrs,
    } = this.props;
    const { name } = this.getBackLinkInfo();

    return (
      <BackLink
        showTooltip={ notAllSavedTickets && !number }
        text={ name }
        alternativeDesign={ isSmartAgent }
        labelTooltip={ LABELS.TOOLTIP_BACK }
        onClick={ this.handleGoBack }
        qaAttr={ qaAttrs.backLink }
      />
    );
  };

  renderPage = () => {
    const {
      trainsService,
      isCarPage,
      stores: {
        trainStore: {
          train,
          car,
          loadingCarDetails,
        },
      },
      qaAttrs,
    } = this.props;

    const hasERegistration = car && car.ERegistration;

    const children = React.cloneElement(this.props.children, {
      trainService: trainsService,
      onAddToCart: this.handleAddToCart,
      onAddToFavorite: this.handleFavoriteToggle,
      onAddToNote: this.handleAddToNote,
    });

    const shouldHideFavorite = isSmartAgent || train?.FavoriteId === undefined;

    const trainName = train?.Number2 || train?.Number || '';

    return (
      <div className={ styles.page }>
        { this.renderBackLink() }
        <div className={ styles.info }>
          <TrainPrimaryInfoBlock
            name={ trainName }
            stations={ [train.StationDepart, train.StationArrive] }
            departureDate={ train?.DepartureDate }
            arrivalDate={ this.getArrivalDate() }
            departureDateLocal={ train?.DepartureDateLocal }
            arrivalDateLocal={ train?.ArrivalDateLocal }
            favoriteId={ train?.FavoriteId }
            train={ train }
            onFavoriteToggle={ this.handleFavoriteToggle }
            shouldHideFavorite={ shouldHideFavorite }
            hasERegistration={ hasERegistration }
            showERegistrationWarning={ !loadingCarDetails && isCarPage }
            qaAttrs={ qaAttrs }
          />
        </div>
        <>
          { children }
        </>
      </div>
    );
  };

  renderLoading = () => {
    const { isCarPage } = this.props;
    const { isAddToCartBack } = this.state;

    const defaultLabel = isCarPage ? LABELS.CAR_SEARCH : LABELS.TRAIN_SEARCH;
    const label = isAddToCartBack ? LABELS.TRAIN_SEARCH_BACK : defaultLabel;

    const qaAttrCarAndTrainSearch = isCarPage ? QA_ATTRIBUTES.train.result.loading : QA_ATTRIBUTES.search.train.history.loading;
    const qaAttr = isAddToCartBack ? QA_ATTRIBUTES.train.current.loadingBack : qaAttrCarAndTrainSearch;

    return (
      <PageLoader text={ label } qaAttr={ qaAttr } />
    );
  };

  render() {
    const {
      stores: {
        trainStore: {
          loading,
        },
      },
      featureFlagsService,
      trainsService,
    } = this.props;

    const html = loading ? this.renderLoading() : this.renderPage();

    return (
      <div className={ styles.wrap }>
        { /* @ts-ignore */ }
        <TrainsSearchMenu
          subMenu
          trainsService={ trainsService }
          onSearch={ this.handleSearch }
          featureFlagsService={ featureFlagsService }
        />
        { html }
      </div>
    );
  }
}

export { TrainResultPage };
