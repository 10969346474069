import React, { useState } from 'react';
import { Button, Input, Text, Textarea } from 'new-ui';

import { getText } from '../../../../../../../i18n';

import { INTEGER } from '../../../../../../bi/constants/regExp';

import styles from './styles/index.module.css';

const LABELS = {
  PEOPLE: getText('event:calculator.city.people'),
  DAY: getText('event:calculator.city.day'),
  COMMENT: getText('event:calculator.city.comment'),
  BUTTON: {
    DONE: getText('event:calculator.button.doneForgein'),
    CANCEL: getText('event:calculator.button.cancel'),
  },
};

const getIntegerNumber = (value: string) => INTEGER.test(value);

const ForgeinCity = () => {
  const [people, setPeople] = useState('');
  const [day, setDay] = useState('');
  const [comment, setComment] = useState('');

  const setValue = (value: string, setValues: (value: string) => void) => {
    if (getIntegerNumber(value) || value === '') {
      setValues(value);
    }
  };

  return (
    <div className={ styles.wrapper }>
      <div className={ styles.wrapper_input }>
        <div className={ styles.subwrapper_input }>
          <Text className={ styles.text }>
            { LABELS.PEOPLE }
          </Text>
          <Input
            className={ styles.input }
            value={ people }
            onChange={ (value) => setValue(value, setPeople) }
          />
        </div>
        <div className={ styles.subwrapper_input }>
          <Text className={ styles.text }>
            { LABELS.DAY }
          </Text>
          <Input
            className={ styles.input }
            value={ day }
            onChange={ (value) => setValue(value, setDay) }
          />
        </div>
      </div>
      <div className={ styles.wrapper_subinput }>
        <Text className={ styles.text }>
          { LABELS.COMMENT }
        </Text>
        <Textarea
          withInput
          noneResize
          className={ styles.text_area }
          value={ comment }
          onChange={ setComment }
        />
      </div>
      <div className={ styles.wrapper_buttons }>
        <Button type='secondary'>
          { LABELS.BUTTON.DONE }
        </Button>
        <Button
          type='textual'
          className={ styles.second }
        >
          { LABELS.BUTTON.CANCEL }
        </Button>
      </div>
    </div>
  );
};

export { ForgeinCity };
