import React, { useState } from 'react';
import { Moment } from 'moment';
import { Datepicker, Input, LinkButton, Text } from 'new-ui';

import { getText } from '../../../../../../../i18n';

import { FieldLabel } from '../../../../../../components/FieldLabel';
import { FormWrapper } from '../../../../../../components/FormWrapper';

import DOCUMENTSFIELDS from '../../../../../../bi/constants/documentsFields';
import { IDS_FOR_INTEGRATION_TESTS } from '../../../../../../bi/constants/idsForInregrationTests';
import { QA_ATTRIBUTES } from '../../../../../../bi/constants/attributesForTests';

import { getMoment, momentObject } from '../../../../../../bi/utils/formatDate';
import { checkUaWithDomesticPassport } from '../../../../../../bi/utils/documents';
import { onlyNumbersOrEmpty } from '../../../../../../bi/utils/text';

import styles from '../index.module.css';

const DATEPICKER_PLACEHOLDER = '__.__.____';

const LABELS = {
  NAME: getText('settings:employees.employee.steps.documents.name'),
  LAST_NAME: getText('settings:employees.employee.steps.documents.surname'),
  PATRONYMIC: getText('settings:employees.employee.steps.documents.domesticOrForeign.patronymic'),
  DOCUMENT_NUMBER: getText('settings:employees.employee.steps.documents.domesticOrForeign.number'),
  DOCUMENT_DUE_DATE: getText('settings:employees.employee.steps.documents.domesticOrForeign.dueDate'),
  EDIT_TRANSLITERATION_TITLE: getText('settings:employees.employee.steps.documents.domesticOrForeign.transliteration.title'),
  CHECK_TRANSLITERATION: getText('settings:employees.employee.steps.documents.domesticOrForeign.transliteration.checkTransliteration'),
  HIDE_EDIT_TRANSLITERATION: getText('settings:employees.employee.steps.documents.domesticOrForeign.transliteration.edit'),
  SHOW_EDIT_TRANSLITERATION: getText('settings:employees.employee.steps.documents.domesticOrForeign.transliteration.show'),
  LATIN_LASTNAME: getText('settings:employees.employee.steps.documents.domesticOrForeign.lastName'),
  LATIN_FIRSTNAME: getText('settings:employees.employee.steps.documents.domesticOrForeign.firstName'),
  LATIN_PATRONYMIC: getText('settings:employees.employee.steps.documents.domesticOrForeign.latinPatronymic'),
  REQUIRED_FIELD: getText('settings:employees.employee.steps.documents.requiredField'),
};

const { employee: { documents: { passport } } } = QA_ATTRIBUTES;

interface IDomesticOrForeignDocument {
  error: {
    LastName: string,
    FirstName: string,
    MiddleName: string,
    Name: string,
    Surname: string,
    Patronymic: string,
    Number: string,
  },
  document: {
    LastName: string,
    FirstName: string,
    MiddleName: string,
    Surname: string,
    Name: string,
    Patronymic: string,
    Number: string,
    DueDate: string,
    Type: string,
    NoPatronymic: boolean,
  },
  employeeCode: string,
  onResetErrors(type: string): void,
  onValidationInput(type: string): void,
  onInputChange(value: string, type: string): void,
  onNumberChange(value: string): void,
  onDateChange(type: string, value: string | Moment | null): void,
  onBlurInput(value: string, type: string): void,
  isDomestic: boolean,
  index: number,
  disabled: boolean,
  disabledFieldsNamesChangeAirTrip: boolean,
  renderCheckboxNoPatronymic: (value: boolean, type: string) => React.ReactNode;
}

const DomesticOrForeignDocument = ({
  error: {
    LastName: errLastName,
    FirstName: errFirstName,
    MiddleName: errMiddleName,
    Number: errNumber,
    Name: errName,
    Surname: errSurname,
    Patronymic: errPatronymic,
  },
  document: {
    Name,
    Surname,
    Patronymic,
    FirstName,
    LastName,
    MiddleName,
    Number,
    DueDate,
    Type,
    NoPatronymic,
  },
  employeeCode,
  onResetErrors,
  onValidationInput,
  onInputChange,
  onNumberChange,
  onDateChange,
  onBlurInput,
  isDomestic,
  index,
  disabled,
  disabledFieldsNamesChangeAirTrip,
  renderCheckboxNoPatronymic,
}: IDomesticOrForeignDocument) => {
  const [showForm, setShowForm] = useState(false);

  const formHtml = showForm && (
    <div className={ styles.row }>
      <div className={ styles.item }>
        <FieldLabel text={ LABELS.LATIN_LASTNAME } mustHave />
        <Input
          className={ styles.disabled }
          value={ LastName }
          onFocus={ () => onResetErrors(DOCUMENTSFIELDS.LASTNAME) }
          onBlur={ () => onBlurInput(LastName, DOCUMENTSFIELDS.LASTNAME) }
          onChange={ (value) => onInputChange(value, DOCUMENTSFIELDS.LASTNAME) }
          error={ errLastName }
          disabled
        />
      </div>
      <div className={ styles.item }>
        <FieldLabel text={ LABELS.LATIN_FIRSTNAME } mustHave />
        <Input
          className={ styles.disabled }
          value={ FirstName }
          onFocus={ () => onResetErrors(DOCUMENTSFIELDS.FIRSTNAME) }
          onBlur={ () => onBlurInput(FirstName, DOCUMENTSFIELDS.FIRSTNAME) }
          onChange={ (value) => onInputChange(value, DOCUMENTSFIELDS.FIRSTNAME) }
          error={ errFirstName }
          disabled
        />
      </div>
      <div className={ styles.item }>
        <FieldLabel text={ LABELS.LATIN_PATRONYMIC } mustHave />
        <Input
          value={ MiddleName }
          onFocus={ () => onResetErrors(DOCUMENTSFIELDS.MIDDLENAME) }
          onBlur={ () => onBlurInput(MiddleName, DOCUMENTSFIELDS.MIDDLENAME) }
          onChange={ (value) => onInputChange(value, DOCUMENTSFIELDS.MIDDLENAME) }
          error={ errMiddleName }
          disabled={ disabled }
        />
      </div>
    </div>
  );

  const showTransliterated = Patronymic.length;

  const editTitle = showForm ? LABELS.HIDE_EDIT_TRANSLITERATION : LABELS.SHOW_EDIT_TRANSLITERATION;
  const textHtml = !!showTransliterated && (
    <>
      <Text className={ styles.translit } type='NORMAL_14_130'>
        { LABELS.EDIT_TRANSLITERATION_TITLE }
        <b>{ `${LastName} ${FirstName} ${MiddleName} ` }</b>
        <LinkButton
          tabIndex={ -1 }
          onClick={ () => setShowForm(!showForm) }
        >
          ({ editTitle })
        </LinkButton>
        { LABELS.CHECK_TRANSLITERATION }
      </Text>
      { formHtml }
    </>
  );

  const mustHaveCondition = !!Surname.trim().length || !!Name.trim().length || !!Number.trim().length || !!DueDate;

  const renderRequiredFieldInfo = () => {
    if (!mustHaveCondition) return null;

    return (
      <Text
        type='NORMAL_14'
      >
        <span className={ styles.mustHave }>*</span>
        { LABELS.REQUIRED_FIELD }
      </Text>
    );
  };

  const isUaWithDomesticPassport = checkUaWithDomesticPassport(employeeCode, Type);
  const mustHaveDueDate = mustHaveCondition && !isUaWithDomesticPassport;

  const validateNumberFn = isUaWithDomesticPassport
    ? () => onBlurInput(Number, DOCUMENTSFIELDS.NUMBER)
    : () => onValidationInput(DOCUMENTSFIELDS.DUEDATE);

  const ids = isDomestic ? IDS_FOR_INTEGRATION_TESTS.EMPLOYEE.DOCUMENTS.DOMESTIC : IDS_FOR_INTEGRATION_TESTS.EMPLOYEE.DOCUMENTS.FOREIGN;

  const preparedDueDate = DueDate ? momentObject(DueDate) : '';

  const wrapperClassName = disabled ? styles.disabled : '';
  const disabledItemClassName = !disabled && disabledFieldsNamesChangeAirTrip ? styles.disabled : '';

  return (
    <FormWrapper className={ wrapperClassName }>
      <div className={ styles.row }>
        <div className={ `${styles.item} ${disabledItemClassName}` }>
          <FieldLabel text={ LABELS.LAST_NAME } mustHave={ mustHaveCondition } />
          <Input
            id={ ids.SURNAME }
            value={ Surname }
            onFocus={ () => onResetErrors(DOCUMENTSFIELDS.SURNAME) }
            onBlur={ () => onBlurInput(Surname, DOCUMENTSFIELDS.SURNAME) }
            onChange={ (value) => onInputChange(value, DOCUMENTSFIELDS.SURNAME) }
            error={ errSurname }
            disabled={ disabledFieldsNamesChangeAirTrip }
            qaAttr={ `${passport}-${ids.SURNAME}-${index}` }
          />
        </div>
        <div className={ `${styles.item} ${disabledItemClassName}` }>
          <FieldLabel text={ LABELS.NAME } mustHave={ mustHaveCondition } />
          <Input
            id={ ids.NAME }
            value={ Name }
            onFocus={ () => onResetErrors(DOCUMENTSFIELDS.NAME) }
            onBlur={ () => onBlurInput(Name, DOCUMENTSFIELDS.NAME) }
            onChange={ (value) => onInputChange(value, DOCUMENTSFIELDS.NAME) }
            error={ errName }
            disabled={ disabledFieldsNamesChangeAirTrip }
            qaAttr={ `${passport}-${ids.NAME}-${index}` }
          />
        </div>
        <div className={ `${styles.item} ${disabledItemClassName}` }>
          <FieldLabel text={ LABELS.PATRONYMIC } mustHave />
          <Input
            value={ Patronymic }
            onFocus={ () => onResetErrors(DOCUMENTSFIELDS.PATRONYMIC) }
            onBlur={ () => onBlurInput(Patronymic, DOCUMENTSFIELDS.PATRONYMIC) }
            onChange={ (value) => onInputChange(value, DOCUMENTSFIELDS.PATRONYMIC) }
            error={ errPatronymic }
            disabled={ disabledFieldsNamesChangeAirTrip }
          />
        </div>
      </div>
      { textHtml }
      <div className={ styles.row }>
        <div className={ styles.item }>
          <FieldLabel text={ LABELS.DOCUMENT_NUMBER } mustHave={ mustHaveCondition } />
          <Input
            id={ ids.NUMBER }
            value={ Number }
            onChange={ value => onNumberChange(value) }
            withValidation
            validateFn={ v => (!isDomestic ? v.length > 9 || !onlyNumbersOrEmpty(v) : false) }
            onBlur={ validateNumberFn }
            error={ errNumber }
            debounceMs={ 0 }
            disabled={ disabled }
            qaAttr={ `${passport}-${ids.NUMBER}-${index}` }
          />
        </div>
        <div className={ styles.item }>
          <FieldLabel text={ LABELS.DOCUMENT_DUE_DATE } mustHave={ mustHaveDueDate } />
          <Datepicker
            closeOnTabOut
            id={ ids.DUE_DATE }
            inputClassName={ styles.datepicker }
            value={ preparedDueDate }
            min={ getMoment().startOf('d') }
            onChange={ value => onDateChange(DOCUMENTSFIELDS.DUEDATE, value) }
            placeholder={ DATEPICKER_PLACEHOLDER }
            disabled={ disabled }
            qaAttr={ `${passport}-${ids.DUE_DATE}-${index}` }
          />
        </div>
        { renderCheckboxNoPatronymic(NoPatronymic, Type) }
        <div className={ styles.requiredField } >
          { renderRequiredFieldInfo() }
        </div>
      </div>
    </FormWrapper>
  );
};

export { DomesticOrForeignDocument };
