import { getText } from '../../../i18n';

const LABELS = {
  TRAIN: {
    AUTO: getText('event:calculator.city.const.train.auto'),
    BUS: getText('event:calculator.city.const.train.bus'),
  },
  HOTEL: {
    THREE: getText('event:calculator.city.const.hotel.three'),
    FOUR: getText('event:calculator.city.const.hotel.four'),
    FIVE: getText('event:calculator.city.const.hotel.five'),
  },
  HALL: {
    SMALL: getText('event:calculator.city.const.hall.small'),
    MEDIUM: getText('event:calculator.city.const.hall.medium'),
    BIG: getText('event:calculator.city.const.hall.big'),
  },
};

export const HOTELS_MICE = [
  {
    value: LABELS.HOTEL.THREE,
    id: '3',
  },
  {
    value: LABELS.HOTEL.FOUR,
    id: '4',
  },
  {
    value: LABELS.HOTEL.FIVE,
    id: '5',
  },
];

export const HALL_MICE = [
  {
    value: LABELS.HALL.SMALL,
    id: 'Small',
  },
  {
    value: LABELS.HALL.MEDIUM,
    id: 'Medium',
  },
  {
    value: LABELS.HALL.BIG,
    id: 'Large',
  },
];

export const TRANSFER_MICE = [
  {
    value: LABELS.TRAIN.AUTO,
    id: 'Car',
  },
  {
    value: LABELS.TRAIN.BUS,
    id: 'Bus',
  },
];

export const FOOD_MICE = {
  COFEE_BREAK: 'CoffeeBreak',
  LUNCH: 'Lunch',
  DINNER: 'Dinner',
  BUFFET: 'Buffet',
  BANQUET: 'Banquet',
};

export const MICE_SERVICES = {
  HOST: 'Host',
  DJ: 'Dj',
  TEAM_BUILDING: 'TeamBuilding',
  PHOTOGRAPHER: 'Photographer',
};

export const CHECK_MICE = {
  FOOD: 'food',
  SERVICES: 'services',
};
