import React, { FC } from 'react';
import { Text, PageLoader } from 'new-ui';

import { getText } from '../../../../../i18n';

import { DocumentRow } from './row';

import { PackagesItems, ReportService } from '../../../../bi/types/report';

import styles from '../../styles/table.module.css';

const LABELS = {
  EMPTY_LIST: getText('reports:documents.table.emptyList'),
  DATE: getText('reports:documents.table.createDate'),
  NUMBER: getText('reports:documents.table.number'),
  PERIOD: getText('reports:documents.table.period'),
  DOCUMENT: getText('reports:documents.table.document'),
};

interface TableProps {
  items: PackagesItems[],
  loading: boolean,
  currentCompany: number,
  reportService: ReportService,
}

const renderRow = (
  items: PackagesItems[],
  handleDownloadDocument: (packageId: number, format: string, documentId: number) => void,
  onDownloadAllInvoiceByData: (documentId: number) => void) => {
  if (!items.length) {
    return <Text className={ styles.empty }>{LABELS.EMPTY_LIST}</Text>;
  }

  return items.map((item: PackagesItems, key) => (
    <DocumentRow
      key={ `${key}__${item.Id}` }
      item={ item }
      onDownloadDocument={ handleDownloadDocument }
      onDownloadAllInvoiceByData={ onDownloadAllInvoiceByData }
    />
  ));
};

const renderContent = (
  items: PackagesItems[],
  loading:boolean,
  handleDownloadDocument: (packageId: number, format: string, documentId: number) => void,
  onDownloadAllInvoiceByData: (documentId: number) => void) => {
  if (loading) {
    return (
      <div className={ styles.loading }>
        <PageLoader />
      </div>
    );
  }

  return renderRow(items, handleDownloadDocument, onDownloadAllInvoiceByData);
};

const Table: FC<TableProps> = ({ items, loading, currentCompany, reportService }) => {
  const handleDownloadDocument = (packageId: number, format: string, documentId: number) => {
    reportService.downloadDocument(currentCompany, packageId, documentId, format);
  };

  const onDownloadAllInvoiceByData = (documentId: number) => reportService.downloadAllInvoiceByData(currentCompany, documentId);

  const content = renderContent(items, loading, handleDownloadDocument, onDownloadAllInvoiceByData);

  return (
    <div className={ styles.wrap }>
      <div className={ styles.header }>
        <Text className={ styles.date }>{ LABELS.DATE }</Text>
        <Text className={ styles.number }>{ LABELS.NUMBER }</Text>
        <Text className={ styles.period }>{ LABELS.PERIOD }</Text>
        <Text className={ styles.document }>{ LABELS.DOCUMENT }</Text>
      </div>
      { content }
    </div>
  );
};

export { Table };
