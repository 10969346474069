// @ts-nocheck
import CHECKOUT_ACTION from './action';
import CheckoutStore from './checkoutStore';
import { ICheckoutStore } from './types';

class Checkout {
  checkoutStore: ICheckoutStore;
  api: any;

  constructor(api: any) {
    this.api = api.cart;
    this.checkoutStore = CheckoutStore();
  }

  get = () => this.checkoutStore.getState();

  clearState = () => this.checkoutStore.dispatch({
    type: CHECKOUT_ACTION.CHECKOUTCLEARSTATE,
  });

  start = () => this.checkoutStore.dispatch({
    type: CHECKOUT_ACTION.CHECKOUTLOAD,
  });

  load = (id: string) => this.api.getCartById(id).then((res) => {
    this.checkoutStore.dispatch({
      type: CHECKOUT_ACTION.UPDATE,
      payload: res,
    });
    this.checkoutStore.dispatch({
      type: CHECKOUT_ACTION.VALIDATERATE,
      payload: res,
    });
    this.validateEmployees(id);

    return res;
  });

  validateEmployees = (cartId: string) => this.api.validateEmployees(cartId)
    .then((payload) => this.checkoutStore.dispatch({ type: CHECKOUT_ACTION.CHECKOUTVALIDATION, payload }))
    .catch(() => this.checkoutStore.dispatch({ type: CHECKOUT_ACTION.CHECKOUTVALIDATION, payload: null }));

  loadPreCheckout = (id: string) => this.api.preCheckout(id).then((res) => {
    this.checkoutStore.dispatch({
      type: CHECKOUT_ACTION.PREUPDATE,
      payload: res,
    });
  });

  loadDiscount = async (id: string) => {
    try {
      const res = await this.api.getDiscount(id);

      this.checkoutStore.dispatch({ type: CHECKOUT_ACTION.LOADDISCOUNT, payload: res });

      return res;
    } catch (error) {
      this.checkoutStore.dispatch({ type: CHECKOUT_ACTION.LOADDISCOUNT, payload: [] });

      return [];
    }
  };

  changeAccept = (value: boolean) => this.checkoutStore.dispatch({
    type: CHECKOUT_ACTION.CHECKOUTUPDATEACCEPT,
    payload: value,
  });

  cancellationInfo = (id: number) => this.api.cancellationInfo(id).then((res) => {
    this.checkoutStore.dispatch({
      type: CHECKOUT_ACTION.CHECKOUTUPDATERULES,
      payload: {
        Id: id,
        Rules: res,
      },
    });
  });

  rename = (params: any) => this.api.rename(params).then(() =>
    this.checkoutStore.dispatch({
      type: CHECKOUT_ACTION.CHECKOUTUPDATENAME,
      payload: params,
    }));

  applyDiscount = (value: string) => this.api.applyDiscount(this.get().id, value);

  subscribeCart = (cb: any) => this.checkoutStore.subscribe(cb);

  itemsCartReservation = async (idCart: any) => {
    try {
      this.checkoutStore.dispatch({
        type: CHECKOUT_ACTION.CHECKOUTLOAD,
      });

      const result = await this.api.itemsCartReservation(idCart);

      this.checkoutStore.dispatch({
        type: CHECKOUT_ACTION.LOAD_ITEMS_STATUS_RESERVE,
        payload: result,
      });

      await this.load(idCart);
      await this.loadPreCheckout(idCart);
    } catch (error) {
      this.checkoutStore.dispatch({
        type: CHECKOUT_ACTION.LOAD_ITEMS_STATUS_RESERVE,
        payload: [{ Success: false, ErrorMessage: '' }],
      });

      await this.load(idCart);
    }
  };
}

export default Checkout;
