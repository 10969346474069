import React, { Component } from 'react';
import { observer } from 'mobx-react';
import {
  IconButton,
  Tag,
  Text,
  Tooltip,
  Icon,
  ItemPanel,
  StyledWrapper,
  Button,
  DotLoading,
  NoResults,
} from 'new-ui';

import { getText, getTextArray } from '../../../../i18n';

import { withStores } from '../../../bi/context';
import { MOBX_STORES } from '../../../bi/context/stores';

import { getMinMaxCostsByCars } from '../../../bi/utils/train';
import toDecline from '../../../bi/utils/toDecline';
import parseUnix from '../../../bi/utils/parseDateTime';
import MoneyFormat from '../../../bi/utils/money';
import textTransformation from '../../../bi/utils/textTransformation';
import { MainAnalytic } from '../../../bi/utils/analytics';

import { PATTERN } from '../../../bi/constants/dateFormats';
import { SERVICETYPE } from '../../../bi/constants/serviceType';
import FAVORITESACTION from '../../../bi/constants/favorites';
import ROUTES from '../../../bi/constants/routes';
import { TRAIN_RESULT_SORT } from '../../../bi/constants/train';
import { QA_ATTRIBUTES } from '../../../bi/constants/attributesForTests';
import { POPUP_NAME } from '../../../bi/constants/popups';

import { StickyPanel } from '../../../components/Sticky';
import TrainItem from '../../../components/TrainItem';
import ItemLayout from '../../../components/ItemLayout';
import { SortSelect } from '../../../components/SortSelect';
import ContextAdvertising from '../../../components/ContextAdvertising';
import Train from '../../../../images/mice/context/train.webp';
import { TrainTicket } from './ticket';
import { TrainTicketWithTransfer } from './ticketWithTransfer';

import { isSmartAgent } from '../../../bi/utils/env';

import { TrainResultWrapProps, TrainResultWrapState, ItemType } from './types';
import { ISavedTicket, SavedTicket } from '../../../bi/services/trains/store/types';
import { ISourcesWithTransfer, ITrain } from '../../../bi/types/train';

import styles from '../styles/result.module.css';

const LABELS = {
  NOTIFICATIONS: {
    DELETED_SUCCESS: getText('trains:results.notifications.deletedSuccess'),
    DELETED_FAILED: getText('trains:results.notifications.deletedFailed'),
    ADDED_SUCCESS: getText('trains:results.notifications.addedSuccess'),
    ADDED_FAILED: getText('trains:results.notifications.addedFailed'),
  },
  FAVORITE_NOT_FOUND_MESSAGE: (number: string, date: string) => getText('trains:results.notFoundFavorite.message', { number, date }),
  FAVORITE_NOT_FOUND_BUTTON: getText('trains:results.notFoundFavorite.button'),
  SAVED_TICKET_CHANGE: getText('trains:results.chooseAnother'),
  CHOSEN_TRAIN: (depart: string, arrive: string) => getText('trains:results.chosenTrain', { depart, arrive }),
  CHOSEN_TRAINS: getText('trains:results.selectedTrains'),
  TICKETS: getTextArray('trains:results.declineTickets'),
  PER: getText('trains:results.per'),
  TRAIN_NUMBER: (number: string, from: string, to: string, departure: string, arrival: string) =>
    getText('trains:results.trainNumber',
      {
        number,
        from,
        to,
        departure: parseUnix(departure).format(PATTERN.DAY_WITH_DIGITALMONTH),
        arrival: parseUnix(arrival).format(PATTERN.DAY_WITH_DIGITALMONTH),
      },
    ),
  COST_MIN_MAX: (min: number, max: number) =>
    getText('trains:results.costMinMax', {
      min: MoneyFormat.money(min, true),
      max: MoneyFormat.money(max, true),
    }),
  RUB: getText('trains:results.rub'),
  NOT_FOUND: getText('trains:results.notFound'),
  CHANGE_PARAMS_OF_SEARCH: getText('trains:results.changeSearchParams'),
  CHANGE_PARAMS_OF_FILTERS: getText('trains:results.changeFilterParams'),
  RESET_FILTERS: getText('trains:results.resetFilters'),
  TITLES_WITH_TRANSFER: {
    NOT_SOURCES: getText('trains:results.titlesWithTransfers.notSources'),
    WITH_SOURCES: getText('trains:results.titlesWithTransfers.withSources'),
  },
  TRANSFERS: {
    LOADING: getText('trains:results.transfers.loading'),
    OPTIONS: getText('trains:results.transfers.options'),
    FIND_ROUTES: getText('trains:results.transfers.findRoutes'),
    FIND: getText('trains:results.transfers.find'),
  },
};

const STICKY_PANEL_ADJUSTMENT = 188;

@withStores([
  MOBX_STORES.TRAIN_SEARCH,
  MOBX_STORES.TRAIN_TICKETS,
  MOBX_STORES.TRAIN_SAVED_TICKET,
  MOBX_STORES.TRAIN_SAVED_TICKETS,
])
@observer
class TrainResultWrap extends Component<TrainResultWrapProps, TrainResultWrapState> {
  state = {
    transfersLoading: false,
    transfersLoadingClicked: false,
  };

  handleChangeFavorite = (item: ITrain, action: string) => {
    const { favoritesService, trainsService, notificationService, history } = this.props;

    if (action === FAVORITESACTION.REMOVE) {
      return favoritesService.removeItem(item.FavoriteId)
        .then(() => {
          notificationService.send({
            message: LABELS.NOTIFICATIONS.DELETED_SUCCESS,
            level: 'success',
            qaAttr: QA_ATTRIBUTES.search.train.notification.removeFavorite,
          });

          trainsService.changeFavoriteStatus(item.TrainId, '');
        })
        .catch(() => {
          notificationService.send({
            message: LABELS.NOTIFICATIONS.DELETED_FAILED,
            level: 'error',
          });
        });
    }

    return trainsService.addToFavorite(item)
      .then((favoriteId: string) => {
        notificationService.send({
          message: LABELS.NOTIFICATIONS.ADDED_SUCCESS,
          level: 'success',
          onClick: () => history.push(ROUTES.FAVORITES),
          qaAttr: QA_ATTRIBUTES.search.train.notification.addFavorite,
        });

        trainsService.changeFavoriteStatus(item.TrainId, favoriteId);
      })
      .catch(() => {
        notificationService.send({
          message: LABELS.NOTIFICATIONS.ADDED_FAILED,
          level: 'error',
        });
      });
  };

  goToMice = () => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.MICE.CONTEXT.TRAIN.PRESSED);

    window.open(ROUTES.EVENT_CONTEXT, '_blank');
  };

  handleChangeFavorites = (item: ITrain, action: string) => {
    const { favoritesService, notificationService, trainsService, history } = this.props;

    if (action === FAVORITESACTION.REMOVE) {
      return favoritesService.removeItems(item.Trains)
        .then(() => {
          notificationService.send({
            message: LABELS.NOTIFICATIONS.DELETED_SUCCESS,
            level: 'success',
          });

          trainsService.removeFavoritesStatus(item);
        })
        .catch(() => notificationService.send({
          message: LABELS.NOTIFICATIONS.DELETED_FAILED,
          level: 'error',
        }));
    }

    const failed = () => notificationService.send({
      message: LABELS.NOTIFICATIONS.ADDED_FAILED,
      level: 'error',
    });

    return trainsService.addFavorites(item)
      .then((res) => {
        if (res) {
          notificationService.send({
            message: LABELS.NOTIFICATIONS.ADDED_SUCCESS,
            level: 'success',
            onClick: () => history.push(ROUTES.FAVORITES),
          });

          return trainsService.addFavoritesStatus(res);
        }

        return failed();
      })
      .catch(failed);
  };

  handleSortSelectWrap = <Fn extends (...args: any[]) => void>(
    func: Fn, value: Parameters<Fn>[0],
  ) => {
    func(value);
    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.SEARCH.SEARCH_RESULTS_TRAIN_SORTING_CHOSEN(
        TRAIN_RESULT_SORT.find(i => i.value === value)?.analyticValue,
      ),
    );
  };

  handleGetLinkAction = (item: ITrain, searchId: number) => {
    const fnLink = (ticket: ITrain) => {
      const { TrainNumber, StationFrom, StationTo, DepartureDate, ArrivalDate, Cars, TrainId } = ticket;

      const linkUrl = ROUTES.SEARCH.SELECTED_TRAIN(searchId, TrainId);

      const link = window.location.origin + linkUrl;

      const { minCost, maxCost } = getMinMaxCostsByCars(Cars);

      const description = LABELS.TRAIN_NUMBER(TrainNumber, StationFrom, StationTo, DepartureDate, ArrivalDate);
      const costText = LABELS.COST_MIN_MAX(minCost, maxCost);

      return `${description} ${costText} \n\n${link}`;
    };

    return item.Trains ? item.Trains.map(fnLink).join('\n\n\n') : fnLink(item);
  };

  handleChangeFirstRoute = () => {
    const { trainsService, onSearch } = this.props;

    trainsService.setSavedTicket(null);
    trainsService.clearSavedTicketsWithTransfer();
    trainsService.setImmediateSearch(true);

    return onSearch();
  };

  handleSearchTransfers = () => {
    const { onSearchTransfers } = this.props;

    this.setState({ transfersLoading: true, transfersLoadingClicked: true });

    onSearchTransfers().then(() => this.setState({ transfersLoading: false }));
  };

  renderHeaderSavedTicket = () => (
    <IconButton
      className={ styles.button }
      iconType='arrowsChange'
      onClick={ this.handleChangeFirstRoute }
    >
      <Text
        type='NORMAL_14'
        qaAttr={ QA_ATTRIBUTES.train.current.savedTicket.chooseAnother }
      >
        { LABELS.SAVED_TICKET_CHANGE }
      </Text>
    </IconButton>
  );

  renderTooltipSavedTicketsPrice = () => {
    const {
      stores: {
        trainSavedTicketsStore: { mappedSavedTickets },
      },
    } = this.props;

    const isDifferentCarType = mappedSavedTickets.some(({ CarType }: SavedTicket, index: number) =>
      index !== mappedSavedTickets.length - 1 && CarType !== mappedSavedTickets[index + 1].CarType,
    );

    const html = ({ CarType, StationDepart, StationArrive, TrainId, totalPrice }: SavedTicket, index: number) => {
      const depart = textTransformation(StationDepart);
      const arrive = textTransformation(StationArrive);
      const type = isDifferentCarType ? ` (${CarType.toLowerCase()})` : '';

      return (
        <Text
          key={ `price_${TrainId}_${index}` }
          className={ styles.tooltip_price }
          color='white'
          type='NORMAL_14_130'
        >
          { depart } - { arrive }{ type }: <span>{ MoneyFormat.moneyWithDecimal(totalPrice, false) } { LABELS.RUB }</span>
        </Text>
      );
    };

    return (
      <div className={ styles.actions_tooltip }>
        { mappedSavedTickets.map(html) }
      </div>
    );
  };

  renderSavedTicketPrice = (totalPrice: number | null, travellers: number, showTooltip = false) => {
    if (!totalPrice) return null;

    const totalPriceText = (
      <div className={ styles.price } data-qa={ QA_ATTRIBUTES.train.current.savedTicket.price }>
        <Text
          type='bold_24'
        >
          { MoneyFormat.moneyWithDecimal(totalPrice, false) }
        </Text>
        <Text className={ styles.currency }>
          { LABELS.RUB }
        </Text>
      </div>
    );

    const textPerTravellers = travellers > 1 && (
      <Text
        className={ styles.travellers }
        type='NORMAL_14'
        color='gray'
      >
        { LABELS.PER } { travellers } { toDecline(travellers, LABELS.TICKETS) }
      </Text>
    );

    return (
      <Tooltip
        show={ showTooltip }
        position='left'
        renderContent={ this.renderTooltipSavedTicketsPrice }
      >
        <div className={ styles.actions }>
          { totalPriceText }
          { textPerTravellers }
        </div>
      </Tooltip>
    );
  };

  renderCommonSavedTickets = (title: string, children: React.JSX.Element) => (
    <div className={ styles.ticket }>
      <Text
        type='bold_24'
        className={ styles['saved-title'] }
        qaAttr={ QA_ATTRIBUTES.train.current.savedTicket.title }
      >
        { title }
      </Text>
      <ItemPanel
        renderHeader={ this.renderHeaderSavedTicket }
      >
        <div className={ styles.item }>
          { children }
        </div>
      </ItemPanel>
    </div>
  );

  renderSavedTickets = () => {
    const {
      stores: {
        trainSavedTicketsStore: { mappedSavedTickets },
      },
    } = this.props;

    const titles = mappedSavedTickets.map(({ StationDepart, StationArrive }: SavedTicket, index: number) => {
      if (index === mappedSavedTickets.length - 1) {
        return StationArrive;
      }

      return StationDepart;
    }).join(' – ');

    const totalPrices = mappedSavedTickets.reduce((acc: number, { totalPrice }: SavedTicket) => acc + totalPrice, 0);
    const travellersCount = mappedSavedTickets.reduce((acc: number, { travellers }: SavedTicket) => acc + travellers, 0);

    const title = `${LABELS.CHOSEN_TRAINS} ${titles}`;
    const ticket = (item: SavedTicket, ind: number) => (
      <div className={ styles.saved_item } key={ `saved_item_${item.TrainId}_${ind}` }>
        <ItemLayout
          hideActions
          serviceType={ SERVICETYPE.TRAIN }
        >
          <TrainItem item={ item as unknown as ItemType } />
        </ItemLayout>
      </div>
    );

    const html = (
      <div className={ styles.saved_tickets }>
        <div className={ styles.tickets }>
          { mappedSavedTickets.map(ticket) }
        </div>
        <div className={ styles.saved_actions }>
          { this.renderSavedTicketPrice(totalPrices, travellersCount, true) }
        </div>
      </div>
    );

    return this.renderCommonSavedTickets(title, html);
  };

  renderSavedTicket = () => {
    const {
      stores: {
        trainSavedTicketStore: { mappedSavedTicket, savedTicket },
        trainSavedTicketsStore: { mappedSavedTickets },
      },
    } = this.props;

    if (mappedSavedTickets.length > 0) {
      return this.renderSavedTickets();
    }

    if (!mappedSavedTicket) return null;

    const { StationDepart, StationArrive, totalPrice } = mappedSavedTicket;
    const { travellers } = savedTicket as ISavedTicket;
    const title = LABELS.CHOSEN_TRAIN(StationDepart, StationArrive);

    const html = (
      <ItemLayout
        serviceType={ SERVICETYPE.TRAIN }
        html={ this.renderSavedTicketPrice(totalPrice, travellers) }
      >
        <TrainItem
          item={ mappedSavedTicket as unknown as ItemType }
          qaAttrNumber={ QA_ATTRIBUTES.train.current.savedTicket.number }
          qaAttrPlace={ QA_ATTRIBUTES.train.current.savedTicket.place }
        />
      </ItemLayout>
    );

    return this.renderCommonSavedTickets(title, html);
  };

  renderTicketsTitle = () => {
    const { stores: { trainSavedTicketStore: { savedTicket }, trainSavedTicketsStore: { savedTickets } } } = this.props;

    if (!savedTicket && !savedTickets.length) return null;

    const text = () => {
      if (savedTickets.length) {
        const from = savedTickets[savedTickets.length - 1].train.StationArrive;
        const to = savedTickets[0].train.StationDepart;

        return `${from} – ${to}`;
      }

      const { train: { StationArrive, StationDepart } } = savedTicket as ISavedTicket;

      return `${StationArrive} – ${StationDepart}`;
    };

    return (
      <Text
        type='bold_24'
        className={ styles['saved-title'] }
      >
        { text() }
      </Text>
    );
  };

  renderTickets = () => {
    const {
      history,
      travelPolicyList,
      onCopyLinkTrip,
      onGetLinkTrip,
      onAddToFavorite,
      onSelectTrain,
      requestItemId,
      stores: {
        trainTicketsStore: { tickets, searchId },
        trainSavedTicketStore: { mappedSavedTicket },
      },
      appService,
    } = this.props;

    const itemHtml = (item: ITrain, index: number) => (
      // @ts-ignore
      <TrainTicket
        key={ `${searchId}__${index}` }
        ind={ index }
        ticket={ item }
        history={ history }
        appService={ appService }
        travelPolicyList={ travelPolicyList }
        onChangeFavorite={ this.handleChangeFavorite }
        onGetLink={ this.handleGetLinkAction }
        idRequestItem={ requestItemId }
        onGetLinkTrip={ onGetLinkTrip }
        onCopyLinkTrip={ onCopyLinkTrip }
        onAddToFavorite={ onAddToFavorite }
        onSelectTrain={ onSelectTrain }
      />
    );

    return (
      <div data-qa={ QA_ATTRIBUTES.search.train.items } className={ `${!mappedSavedTicket ? styles.list : ''}` }>
        { tickets.map(itemHtml) }
      </div>
    );
  };

  renderTicketsWithTransfer = () => {
    const {
      travelPolicyList,
      requestItemId,
      history,
      onCopyLinkTrip,
      onGetLinkTrip,
      onAddToFavorite,
      onSaveCacheTickets,
      appService,
      stores: {
        trainTicketsStore: { sources, ticketsWithTransfer },
      },
    } = this.props;

    const titleHtml = sources.length > 0 ? (
      <Text className={ styles.title } type='bold_18'>{ LABELS.TITLES_WITH_TRANSFER.WITH_SOURCES }</Text>
    ) : (
      <div className={ styles.warning }>
        <Icon type='warning'/>
        <Text type='bold_18' className={ styles.text }>{ LABELS.TITLES_WITH_TRANSFER.NOT_SOURCES }</Text>
      </div>
    );

    const wrappedStyles = [styles.transfer_tickets];

    if (!sources.length) {
      wrappedStyles.push(styles.no_transfer_tickets);
    }

    const ticketsHtml = (items: ISourcesWithTransfer[], index: number) => (
      // @ts-ignore
      <TrainTicketWithTransfer
        key={ `tickets_with_transfer_${index}` }
        ind={ index }
        tickets={ items }
        appService={ appService }
        idRequestItem={ requestItemId }
        history={ history }
        travelPolicyList={ travelPolicyList }
        onCopyLinkTrip={ onCopyLinkTrip }
        onGetLinkTrip={ onGetLinkTrip }
        onAddToFavorite={ onAddToFavorite }
        onSaveCacheTickets={ onSaveCacheTickets }
        onGetLink={ this.handleGetLinkAction }
        onChangeFavorite={ this.handleChangeFavorites }
      />
    );

    return (
      <div className={ wrappedStyles.join(' ') }>
        { titleHtml }
        { ticketsWithTransfer.map((items, index) => ticketsHtml(items, index)) }
      </div>
    );
  };

  renderTransfersPanel = () => {
    const { transfersLoading, transfersLoadingClicked } = this.state;
    const { stores: { trainTicketsStore: { tickets, ticketsWithTransfer } } } = this.props;

    if (!transfersLoading && transfersLoadingClicked) return null;

    if (transfersLoading) {
      return (
        <div className={ styles['wrapper-loading'] }>
          <DotLoading />
          <Text type='SEMIBOLD_16' className={ styles.text }>{ LABELS.TRANSFERS.LOADING }</Text>
        </div>
      );
    }

    if (tickets.length > 10 || ticketsWithTransfer.length) return null;

    return (
      <StyledWrapper
        className={ styles['wrapper-transplants'] }
        qaAttr={ QA_ATTRIBUTES.search.train.transfer.panel.wrapper }
      >
        <div className={ styles.left }>
          <Icon type='smartdeskTrain' size={ 40 } fill='#AFC4E2'/>
          <div className={ styles['text-wrapper'] }>
            <Text type='SEMIBOLD_16'>{ LABELS.TRANSFERS.OPTIONS }</Text>
            <Text color='gray' type='NORMAL_14'>{ LABELS.TRANSFERS.FIND_ROUTES }</Text>
          </div>
        </div>
        <Button
          className={ styles.search }
          onClick={ this.handleSearchTransfers }
          qaAttr={ QA_ATTRIBUTES.search.train.transfer.panel.button }
        >
          { LABELS.TRANSFERS.FIND }
        </Button>
      </StyledWrapper>
    );
  };

  renderNoResults = () => {
    const {
      stores: {
        trainTicketsStore: { ticketsWithTransfer },
      },
    } = this.props;
    const { transfersLoading, transfersLoadingClicked } = this.state;

    return !transfersLoading && transfersLoadingClicked && !ticketsWithTransfer.length ? (
      <div className={ styles.wrapper_no_results }>
        <NoResults
          renderContent={ () => (
            <div className={ styles.no_results } data-qa={ QA_ATTRIBUTES.train.result.empty }>
              <Text type='bold_18'>
                { LABELS.NOT_FOUND }
              </Text>
              <Text className={ styles.subtext }>
                { LABELS.CHANGE_PARAMS_OF_SEARCH }
              </Text>
            </div>
          ) }
        />
      </div>
    ) : null;
  };

  renderContextAdvertising = () => {
    const {
      aggregationId,
      accountSettingsService: {
        getNoMice,
      },
      stores: {
        trainSearchStore: {
          travellers,
        },
      },
      popupsService: {
        closePopupState,
        store: {
          popupsState: {
            mice_train_group_search,
          },
        },
      },
      featureFlagsService: {
        getHiddenContextAdjMice,
      },
      isShownContext,
      onSetShownContext,
    } = this.props;

    if (travellers < 5
      || !isShownContext
      || getNoMice()
      || aggregationId
      || isSmartAgent
      || !getHiddenContextAdjMice()
    ) {
      return null;
    }

    if (!mice_train_group_search) {
      closePopupState(POPUP_NAME.CONTEXT.TRAIN);
    }

    return (
      <ContextAdvertising
        image={ Train }
        onClick={ this.goToMice }
        onClose={ () => onSetShownContext(false) }
        amplitude={ MainAnalytic.ACTIONS.MICE.CONTEXT.TRAIN.SHOWED }
        classnameBtn={ styles.wrapper_context }
      />
    );
  };

  render() {
    const {
      trainsService: { setSortBy, deleteTag },
      requestItemComment,
      stores: {
        trainTicketsStore: { sortBy, tags, ticketsWithTransfer },
      },
    } = this.props;

    const tagsHtml = !!tags.length && tags.map(tag => {
      const { name, key, readOnly } = tag;

      return (
        <Tag
          label={ name }
          readOnly={ readOnly }
          onClick={ () => deleteTag(tag) }
          key={ key }
          className={ styles.tag }
          qaAttr={ tag.qaAttr }
        />
      );
    });

    const requestCommentHtml = requestItemComment && (
      <div className={ styles.comment }>{ requestItemComment }</div>
    );

    const ticketsWithTransferHtml = ticketsWithTransfer.length > 0 && this.renderTicketsWithTransfer();

    return (
      <div className={ styles.wrapper }>
        <StickyPanel
          adjustment={ STICKY_PANEL_ADJUSTMENT }
          fixedClass={ styles['sticky-panel-fixed'] }
          className={ styles['sticky-panel'] }
        >
          <div className={ styles.header }>
            { requestCommentHtml }
            <div className={ styles.main }>
              <div className={ styles.select }>
                <SortSelect
                  items={ TRAIN_RESULT_SORT }
                  value={ sortBy }
                  onChange={ (value) => this.handleSortSelectWrap(setSortBy, value) }
                  qaAttr={ QA_ATTRIBUTES.search.train.sortSelect }
                />
              </div>
              <div className={ styles.tags }>
                { tagsHtml }
              </div>
            </div>
          </div>
        </StickyPanel>
        { this.renderContextAdvertising() }
        { this.renderSavedTicket() }
        { this.renderTicketsTitle() }
        { this.renderTickets() }
        { ticketsWithTransferHtml }
        { this.renderTransfersPanel() }
        { this.renderNoResults() }
      </div>
    );
  }
}

export { TrainResultWrap };
