import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Button, Text, Tooltip, Checkbox, Input, Select, Icon, PageLoader, ItemPanel } from 'new-ui';

import { getText, currentLng } from '../../../i18n';

import { withStores } from '../../bi/context';
import { MOBX_STORES } from '../../bi/context/stores';

import { TravelPolicyDialog } from '../../components/TravelPolicyDialog';
import { NameErrorsTravelPolicy } from '../../components/NameErrorsTravelPolicy';
import { AddToCartDialog } from './components/AddToCartDialog';
import { TrainCarDirection } from './components/Direction';
import { EntireCompartment } from './components/EntireCompartment';
import { ToNote } from '../../components/ToNote';
import AgencyFee from '../../components/AgencyFee';
import { TrainCar } from './components/CarScheme';

import { checkLetterInPlace } from '../../bi/utils/train';
import MoneyFormat from '../../bi/utils/money';
import toDecline from '../../bi/utils/toDecline';
import { MainAnalytic } from '../../bi/utils/analytics';
import { lpad } from '../../bi/utils/pad';
import { isSmartAgent } from '../../bi/utils/env';

import ANIMATION from '../../bi/constants/animation';
import { BUYTRIPSACCOUNTRIGHT, BUYTRIPSPERSONALRIGHT } from '../../bi/constants/rights';
import { WHITESPACES, DIGITSANDPLUS, STARTS_WITH_NUMBER } from '../../bi/constants/regExp';
import { BUTTON_LABELS } from '../../bi/constants/train';
import ROUTES from '../../bi/constants/routes';
import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';

import { ICompartSection, IFreePlace, IPlace, ISelectedPlace } from '../../bi/types/train';
import { ICar, IParentCar, IPlacesItem, ISeats, PlacesPricesItem } from '../../bi/services/trains/store/types';
import { ANALYTIC_SERVICE_TYPES } from '../../bi/constants/serviceType';

import { InfoProviderIM } from '../../components/InfoProviderIM';

import { ItemType, TrainCarPageProps, TrainCarPagePropsState } from './types';

import styles from './index.module.css';

const LABELS = {
  TO_CART: getText('trains:car.toCart'),
  DISRUPTED_TP_CONTINUE: getText('common:continue'),
  TO_NOTE: getText('trains:car.toNote'),
  NO_PLACES_SELECTED: getText('trains:car.pleaseChooseSeat'),
  SEARCH: getText('trains:car.loader'),
  CAR_NUMBER: getText('trains:car.car'),
  CHOSEN_PLACES: getText('trains:car.chosenPlaces'),
  FREE_SEATS: getText('trains:car.freeSeats'),
  RUB: getText('trains:car.rub'),
  SEATS_WITHOUT_CHOSE: (sum: number) => getText('trains:car.seatsWithoutChoose', { sum }),
  DASH: getText('trains:car.dash'),
  SEAT_SELECTION: getText('trains:car.seatSelection'),
  SEATS_SELECTION: getText('trains:car.seatsSelection'),
  DOWN_SEAT: getText('trains:car.downSeat'),
  CHOOSE_RATE: {
    TOOLTIP: getText('trains:car.chooseRate.tooltip'),
    MAIN: getText('trains:car.chooseRate.main'),
  },
  CHOOSE_ENTIRE_COMPARTMENT: {
    MAIN_KUPEK: getText('trains:car.chooseEntireCompartment.mainKupek'),
    MAIN_SINGLE: getText('trains:car.chooseEntireCompartment.mainSingle'),
    MAIN_BP: getText('trains:car.chooseEntireCompartment.mainBP'),
    TOOLTIP_KUPEK: getText('trains:car.chooseEntireCompartment.tooltipKupek'),
    TOOLTIP_SV: getText('trains:car.chooseEntireCompartment.tooltipSV'),
    TOOLTIP_SAPSAN: getText('trains:car.chooseEntireCompartment.tooltipSapsan'),
  },
  COUNT_OF_DOWN_SEATS: getText('trains:car.countOfDownSeats'),
  COORDINATES_OF_SEATS: getText('trains:car.positionOfSeats'),
  IN_ONE_COMPARTMENT: getText('trains:car.inOneCoupe'),
  COUNT_OF_SEATS_AROUND_WINDOW: getText('trains:car.countOfSeatsAroundWindow'),
  NO_RIGHTS_BUY: getText('trains:car.noRightsBuy'),
  MEETING_ROOM_BUY_FULL_PLACES: (travellersCount: number, travellers: string, price: number) =>
    getText('trains:car.meetingRoom', { travellersCount, travellers, price }),
  ROOM_WITHOUT_SCHEMA_BUY_FULL_PLACES: (carType: string) =>
    getText('trains:car.roomWithoutSchemaBuyFullPlaces', { carType }),
  TRAVELLERS_DECLINES: getText('trains:car.travellersDecline'),
  DOES_NOT_HAVE: getText('trains:car.doesNotHave'),
  MORE_EXPENSIVE: getText('trains:car.moreExpensive'),
  FAMILY_CAR: getText('trains:car.familyCar'),
  HAVE_PETS_PLACES: getText('trains:car.havePetsPlaces'),
  HAVE_PETS_PLACES_SHORT: getText('trains:car.havePetsPlacesShort'),
  PLACE_IS_TAKEN: getText('note:placeIsTaken'),
};

@withStores([
  MOBX_STORES.TRAIN_SEARCH,
  MOBX_STORES.TRAIN,
  MOBX_STORES.TRAIN_SAVED_TICKET,
  MOBX_STORES.TRAIN_SAVED_TICKETS,
  MOBX_STORES.TRAIN_TICKETS,
])
@observer
class TrainCarPage extends Component <TrainCarPageProps, TrainCarPagePropsState> {
  static defaultProps = {
    trainId: '',
    searchId: '',
    number: '',
    service: '',
    carId: 0,
  };

  constructor(props: TrainCarPageProps) {
    super(props);

    const { userSessionService, workspaceService, featureFlagsService } = props;

    const { Companies } = workspaceService.get();
    const { DisableToCartIfTPApply: disableToCartIfTPApply } = featureFlagsService.getFeatureFlags();
    const { travelPolicies } = userSessionService.get();
    const { travelPolicy, rights } = workspaceService;

    this.state = {
      range: {
        from: '',
        to: '',
      },
      selectedPlace: [],
      up: '',
      down: '',
      oneCompartment: null,
      bedding: true,
      companies: Companies,
      showAddToCartDialog: false,
      animatedClass: '',
      showTravelPolicyDialog: false,
      travelPolicyList: travelPolicies,
      accountTravelPolicy: travelPolicy,
      rightsBuyTrip: rights,
      refundable: false,
      disableToCartIfTPApply: disableToCartIfTPApply || false,
      entireCompartment: false,
      initialSelectedPlace: [],
      disableButtonOnRequest: false,
    };
  }

  componentDidMount() {
    const {
      trainsService,
      stores: {
        trainStore: {
          train: {
            Number: trainNumber,
            Cars,
          },
        },
      },
      match: {
        params: {
          trainId,
          searchId,
          number,
          carId,
          service,
        },
      },
    } = this.props;
    const { companies } = this.state;

    const {
      CarDetails: carDetails,
      ClassService: carService,
      Type,
    } = (Cars as any)
      .find((itemCar: ICar) => itemCar.Number === number)
      .Tariffs
      .find((item: IParentCar) => {
        if (item.ClassService && service) {
          return item.ClassService === service;
        }

        return item !== undefined;
      });

    trainsService.getCarPlacesDetails(
      trainId,
      searchId,
      number,
      carId,
      companies,
      trainNumber,
      carService,
      Type,
      carDetails,
      currentLng,
    );
  }

  choiceInitiaPlace = () => {
    this.setState({
      selectedPlace: [...this.state.initialSelectedPlace],
    });
  };

  sendAnalytic = (add = false) => {
    const { trainsService, stores: { trainStore: { train, car, car: { trainType } } } } = this.props;

    const data = {
      car,
      companies: this.state.companies,
      train,
    };

    if (!trainType) {
      trainsService.saveCarsWithoutSchemaStats(data, add);
    }
  };

  // TODO: Жоска
  getPlace = () => {
    const { stores: { trainSearchStore: { travellers }, trainStore: { car } } } = this.props;
    const { trainType, BuyTwo } = car;
    const { range, selectedPlace, entireCompartment } = this.state;
    const result = [];
    const haveSelectedPlaces = selectedPlace && selectedPlace.length;
    const isBuyFull = car.PlacesPrices && car.PlacesPrices[0] && car.PlacesPrices[0].BuyFull;

    if (entireCompartment) {
      result.push({
        place: this.processSelectedPlace(selectedPlace),
        many: true,
      });
    } else if (haveSelectedPlaces && !entireCompartment) {
      const currentSelectedPlace = this.getSelectedPlaces(selectedPlace, isBuyFull);
      result.push(...currentSelectedPlace);
    } else if (range.from || range.to) {
      const rangePlaces = { ...range };
      const buyFullWithoutScheme = !trainType && isBuyFull && BuyTwo;

      if (!rangePlaces.from) rangePlaces.from = rangePlaces.to;

      if (!rangePlaces.to) rangePlaces.to = rangePlaces.from;

      car.Places.forEach((placeNumber: string, key: number) => {
        if (buyFullWithoutScheme && key % 2 !== 0) {
          return;
        }

        const numPlaceNumber = parseInt(placeNumber, 10);
        const numRangeFrom = parseInt(rangePlaces.from, 10);
        const numRangeTo = parseInt(rangePlaces.to, 10);

        const includeInRange = numPlaceNumber >= numRangeFrom && numPlaceNumber <= numRangeTo;

        if (includeInRange && result.length < travellers) {
          const placeNumberTo = buyFullWithoutScheme ? car.Places[key + 1] : placeNumber;

          result.push({
            place: [placeNumber, placeNumberTo],
            many: false,
          });
        }
      });
    } else if (!car.trainType && isBuyFull) {
      const placeNumber = Object.keys(car.PlacesPrices[0].BuyFullPrices).length;
      const buyFullPlaces = (car.Places.slice(0, placeNumber));
      const places = buyFullPlaces.map((item: string) => ({ number: parseInt(item, 10) }));

      const currentSelectedPlace = this.getSelectedPlaces(places, isBuyFull);

      result.push(...currentSelectedPlace);
    } else {
      result.push({
        place: null,
        many: true,
      });
    }

    return result;
  };

  processSelectedPlace = (placeArr: { number: number }[]) =>
    placeArr.map((obj: { number: number }) => lpad(obj.number, 3));

  getSelectedPlaces = (selectedPlace: { number: number } [], isBuyFull: boolean) => {
    const { trainsService, stores: { trainStore: { car } } } = this.props;
    const result: { place: string[], many: boolean }[] = [];

    selectedPlace.forEach(({ number }, index: number) => {
      const value = trainsService.checkSeatsPlace(car, number);

      const place = isBuyFull ? [value] : [value, value];
      const many = !!isBuyFull;

      if (isBuyFull && index !== 0) {
        result[0].place.push(value);
      } else {
        result.push({
          place,
          many,
        });
      }
    });

    return result;
  };

  handleChangeSeats = (type: 'from' | 'to', value: string) => {
    const range = {
      ...this.state.range,
      [type]: value,
    };

    this.setState({
      range,
    });
  };

  getCompartmentObject = (selectedPlace: ISelectedPlace[]) => {
    const { entireCompartment } = this.state;

    if (entireCompartment && selectedPlace.length > 0) {
      const { SpecialTariff, CompartmentNumber } = selectedPlace[0];

      return {
        specialTariff: SpecialTariff,
        compartmentNumber: CompartmentNumber,
      };
    }

    return null;
  };

  prepareDataForAddToCartOrNote = () => {
    const {
      bedding,
      up,
      down,
      oneCompartment,
      selectedPlace,
      refundable,
      entireCompartment,
    } = this.state;
    const { stores: { trainStore: { car } } } = this.props;

    const hasAlternativeChosen = selectedPlace.some(({ hasAlternative }) => hasAlternative) && !refundable;

    const compartmentObject = this.getCompartmentObject(selectedPlace);
    const specialCompartmentFields = entireCompartment
      ? {
        SpecialTariff: compartmentObject?.specialTariff,
        CompartmentNumber: compartmentObject?.compartmentNumber,
      }
      : null;

    return {
      CarNumber: car.Number,
      ClassService: car.ClassService,
      Bedding: bedding,
      OneCompartment: oneCompartment,
      Up: up,
      Down: down,
      parentCar: car,
      IsAlternative: hasAlternativeChosen,
      ...specialCompartmentFields,
    };
  };

  sendNotification = () => {
    this.props.notificationService.send({
      message: LABELS.PLACE_IS_TAKEN,
      type: 'error',
    });
  };

  getTrainAvailability = (
    params,
    places,
    totalPrice,
  ) => {
    this.setState({
      disableButtonOnRequest: true,
    });
    const {
      history,
      stores: {
        trainStore: {
          train,
          car,
        },
      },
      trainsService,
      onAddToCart,
    } = this.props;

    const placeNumber = this.getPlace();
    const readyPlace = placeNumber.flatMap(elem => elem.place);

    return trainsService.getAvailability({
      CarNumber: car.Number,
      TrainId: train.TrainId,
      SearchId: train.SearchId,
      CarId: car.Id,
      Place: readyPlace,
      isSmartAgent: false,
    })
      .then(() => onAddToCart(params, places, totalPrice))
      .catch(() => {
        const { SearchId, TrainId } = train;
        this.sendNotification();

        history.push(ROUTES.SEARCH.SELECTED_TRAIN(SearchId, TrainId));
      });
  };

  handleAddToCart = () => {
    this.setState({
      animatedClass: ANIMATION.MOVETOCART,
      showTravelPolicyDialog: false,
    });

    const {
      history,
      trainsService,
      stores: {
        trainTicketsStore: { searchId },
        trainSavedTicketsStore: { cacheTickets, savedTickets },
        trainStore: { train: { TrainId }, train, car },
        trainSearchStore: { travellers: travellersCount },
      },
    } = this.props;
    const { range: { from, to } } = this.state;

    const params = this.prepareDataForAddToCartOrNote();
    const priceOfSelectedPlace = this.handleCountTotalPrice();
    const places = this.getPlace();
    const buyFull = car.PlacesPrices.some(({ BuyFull }: { BuyFull: boolean }) => BuyFull);

    const totalPriceFn = () => {
      if (priceOfSelectedPlace) {
        return priceOfSelectedPlace;
      }

      if (from || to) {
        return places.reduce((acc, { place }: any) => {
          const foundPlacesPrice = car.PlacesPrices.find(({ Places }) => Places.includes(place[0]));

          if (foundPlacesPrice) {
            return acc + foundPlacesPrice.PriceDetails.Total;
          }

          return acc;
        }, 0);
      }

      if (!car.trainType && buyFull) {
        const buyFullPrices = car.PlacesPrices[0].BuyFullPrices;

        return buyFullPrices[travellersCount];
      }

      const arr = car.PlacesPrices
        .map(({ PriceDetails: { Total } }: { PriceDetails: { Total: number } }) => Total);

      if (arr.length === 1) {
        return arr[0];
      }

      return Math.max(...arr);
    };

    const totalPrice = totalPriceFn();

    this.sendAnalytic(true);

    if (cacheTickets.length > 0) {
      trainsService.setSavedTickets({ car: params, places, totalPrice, TrainId, train, searchId });

      const { currentItem, isChoosingBack, currentBackTickets } = this.props.stores.trainSavedTicketsStore;

      if (currentItem) {
        const linkUrl = `/search/train/${searchId}/${currentItem.TrainId}`;

        return history.push({ pathname: linkUrl });
      }

      if (isChoosingBack && !currentBackTickets.length) {
        trainsService.setImmediateSearch(true);

        return history.push(ROUTES.SEARCH.TRAIN_RESULTS);
      }
    }

    const { isChoosingBack } = this.props.stores.trainSavedTicketsStore;

    if (cacheTickets.length === 0 && isChoosingBack && savedTickets) {
      trainsService.setSavedTicketsWithoutTransfer({ car: params, places, totalPrice, TrainId, train, searchId });
    }

    return this.getTrainAvailability(params, places, totalPrice);
  };

  handleAddToNote = () => {
    const { onAddToNote } = this.props;

    const params = this.prepareDataForAddToCartOrNote();
    const totalPrice = this.handleCountTotalPrice();
    const places = this.getPlace();

    this.sendAnalytic(true);

    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.SEARCH.SEARCH_RESULTS_NOTEBOOK_BUTTON_PRESSED(ANALYTIC_SERVICE_TYPES.TRAIN),
    );

    return onAddToNote(params, places as IPlacesItem[], totalPrice);
  };

  handleAddToCartClick = (placesByTP: PlacesPricesItem[]) => {
    const { stores: { trainStore: { car: { PlacesPrices, trainType } } } } = this.props;
    const { selectedPlace, range: { from }, refundable } = this.state;

    const buyFull = PlacesPrices.some(({ BuyFull }: { BuyFull: boolean }) => BuyFull);
    const withoutSchemaIsBuyFull = buyFull && !trainType;

    const haveNonRefundableSelectedPlace = selectedPlace.some(({ number, hasAlternative }) => (hasAlternative && !refundable) ||
      PlacesPrices.some(({ NonRefundable, Places }: { NonRefundable: boolean, Places: string[] }) =>
        NonRefundable && Places.some((place: string) => parseInt(place, 10) === number),
      ));

    const cartAction = placesByTP.length > 0 ? () => this.toggleTravelPolicyDialog(true) : this.handleAddToCart;
    const showDialogAddToCart = haveNonRefundableSelectedPlace || (!from && !selectedPlace.length && !withoutSchemaIsBuyFull);

    return showDialogAddToCart ? this.toggleAddToCartDialog(true) : cartAction();
  };

  handleAddToCartInDialog = () => {
    this.toggleAddToCartDialog(false);
    this.handleAddToCart();
  };

  setEntireCompartment = (value: boolean) => this.setState({
    entireCompartment: value,
  });

  toggleAddToCartDialog = (state: boolean) => this.setState({
    showAddToCartDialog: state,
  });

  toggleTravelPolicyDialog = (state: boolean) => this.setState({
    showTravelPolicyDialog: state,
  });

  handleChangeValue = (
    field: keyof Pick<TrainCarPagePropsState, 'oneCompartment' | 'down'>,
    value: string | number | null,
  ) => this.setState({ [field]: value } as Pick<TrainCarPagePropsState, 'oneCompartment' | 'down'>);

  handleAnimationEnd = () => this.setState({
    animatedClass: '',
  });

  preparePrice = (value?: string | number) => (typeof value === 'string' ? parseInt(value.replace(WHITESPACES, ''), 10) : value);

  updatePlaces = (
    place: number,
    agentFee: string | number,
    price?: number,
    buyFullPlaces?: number[] | null,
    nonRefundable?: boolean,
    hasAlternative?: boolean,
    altPrice?: string | number,
    altFee?: string | number,
  ) => {
    const { stores: { trainSearchStore: { travellers } } } = this.props;
    const { selectedPlace } = this.state;

    const havePlace = selectedPlace.some(({ number }) => number === place);
    const clonedPlaces = [...selectedPlace];
    const selectedPlaces = buyFullPlaces || [place];

    if (havePlace) {
      const updatedPlaces = clonedPlaces.filter(({ number }) => !selectedPlaces.includes(number));

      return updatedPlaces.map(({ number, price: priceForPlace, agentFee: agentFeeForPlace }) => ({
        number,
        price: priceForPlace,
        agentFee: agentFeeForPlace,
        altPrice: this.preparePrice(altPrice),
        altFee: this.preparePrice(altFee),
        nonRefundable,
        hasAlternative,
      }));
    }

    const travellersPerCoupe = buyFullPlaces ? Object.keys(buyFullPlaces).length : 1;

    const maxCoupe = Math.ceil(travellers / travellersPerCoupe);
    const allCoupe = (clonedPlaces.length + selectedPlaces.length) / travellersPerCoupe;

    if (allCoupe > maxCoupe) {
      clonedPlaces.splice(-selectedPlaces.length);
    }

    const selectedPlacesWithPrices = selectedPlaces.map(number => ({
      number,
      price: this.preparePrice(price),
      agentFee: this.preparePrice(agentFee),
      nonRefundable,
      hasAlternative,
      altPrice: this.preparePrice(altPrice),
      altFee: this.preparePrice(altFee),
    }));

    return [...clonedPlaces, ...selectedPlacesWithPrices];
  };

  handleSelectedPlace = (place: IPlace) => {
    const { number, buyFullPlaces, price, agentFee, nonRefundable, hasAlternative, altPrice, altFee } = place;
    const { selectedPlace, entireCompartment } = this.state;

    const updatePlace = this.updatePlaces(
      number,
      agentFee,
      price,
      buyFullPlaces,
      nonRefundable,
      hasAlternative,
      altPrice,
      altFee,
    );

    if (entireCompartment) return null;

    return this.setState({
      ...this.state,
      selectedPlace: updatePlace,
      refundable: hasAlternative && selectedPlace.every(({ hasAlternative: hA }) => hA) ? true : !nonRefundable,
      entireCompartment: false,
      initialSelectedPlace: updatePlace,
    });
  };

  processCompartment = (compartmentSection: ICompartSection) => {
    const { selectedPlace } = this.state;
    const { number } = selectedPlace[selectedPlace.length - 1];
    const {
      buyFullCompartment,
      compartmentPlaces,
      compartmentPrice,
      compartmentAgentFee,
      SpecialTariff,
      CompartmentNumber,
      compartmentAltPrice,
      compartmentAltFee,
      hasAlternative,
    } = compartmentSection;

    if (buyFullCompartment && (compartmentPlaces as string[]).includes(String(number))) {
      const newPlaces = (compartmentPlaces as string[]).map(place => ({
        number: Number(place),
        price: compartmentPrice,
        agentFee: compartmentAgentFee,
        SpecialTariff,
        CompartmentNumber,
        hasAlternative,
        altPrice: compartmentAltPrice,
        altFee: compartmentAltFee,
      }));

      this.setState({
        selectedPlace: [...newPlaces],
      });
    }
  };

  handleSelectedSection = (section: ICompartSection) => {
    if (section.miniSections) {
      section.miniSections.forEach((element) => {
        this.processCompartment(element);
      });
    } else {
      this.processCompartment(section);
    }
  };

  handleCountTotalPrice = () => {
    const { selectedPlace, refundable, entireCompartment } = this.state;
    const { stores: { trainStore: { car: { PlacesPrices, PlacesByCompartmentPrices = [] } } } } = this.props;

    if (!selectedPlace.length) return null;

    const isLux = PlacesPrices.some(({ BuyFull }: { BuyFull: boolean }) => BuyFull);
    const isCompartment = entireCompartment && PlacesByCompartmentPrices.some(({ BuyFull }: { BuyFull: boolean }) => BuyFull);
    const totalSum = selectedPlace.reduce((sum, { price = 0, altPrice = 0 }) => {
      const preparedPrice = refundable ? price : altPrice;

      return sum + preparedPrice;
    }, 0);

    return isLux || isCompartment ? totalSum / selectedPlace.length : totalSum;
  };

  handleCountTotalFee = () => {
    const { selectedPlace, refundable, entireCompartment } = this.state;
    const {
      stores: {
        trainStore: {
          car: { PlacesPrices, PlacesByCompartmentPrices },
        },
      },
    } = this.props;

    if (!selectedPlace.length) return null;

    const isLux = PlacesPrices.some(({ BuyFull }: { BuyFull: boolean }) => BuyFull);
    const isCompartment = entireCompartment && PlacesByCompartmentPrices.some(({ BuyFull }: { BuyFull: boolean }) => BuyFull);
    const totalSum = selectedPlace.reduce((sum, { agentFee = 0, altFee = 0 }) => {
      const preparedPrice = refundable ? agentFee : altFee;

      return sum + preparedPrice;
    }, 0);

    return isLux || isCompartment ? totalSum / selectedPlace.length : totalSum;
  };

  handleGetPlacesByTP = (unavailableTravelPolicy: boolean) => {
    const { selectedPlace, accountTravelPolicy, refundable } = this.state;
    const { stores: { trainStore: { car } } } = this.props;

    let placesByTP: PlacesPricesItem[] = [];

    if (!selectedPlace.length || !accountTravelPolicy) return placesByTP;

    const tpField = selectedPlace.every(({ hasAlternative }) => hasAlternative) && !refundable ? 'AltTravelPolicy' : 'TravelPolicy';
    selectedPlace.forEach(({ number }) => {
      const tpPlaces = car.PlacesPrices.filter((pp: PlacesPricesItem) => pp[tpField].Apply);
      const result = tpPlaces.length > 0
        ? tpPlaces.filter((tpp: PlacesPricesItem) => tpp.Places.filter((p: string) => parseInt(p, 10) === number).length > 0)
        : [];

      placesByTP = [...placesByTP, ...result];
    });

    if (placesByTP.length > 0 && unavailableTravelPolicy && !!accountTravelPolicy) {
      placesByTP = placesByTP.filter(pp => !!pp[tpField].Errors[accountTravelPolicy.Id]);
    }

    return placesByTP;
  };

  renderFreeSeats = (placesPrices: PlacesPricesItem[], seats: ISeats) => {
    const letters: { letter: string, description: string } [] = [];
    const lettersCache: { [key: string]: boolean } = {};

    const renderMap = (places: string[]) => places.map((place: string, index: number) => {
      const res = checkLetterInPlace(place);

      if (res && !lettersCache[res.letter] && res.letter !== 'А') {
        letters.push(res);
        lettersCache[res.letter] = true;
      }

      let placeHtml: number | string = parseInt(place, 10);

      if (res) {
        const placeNum = place.substring(0, place.length - 1);
        placeHtml = `${parseInt(placeNum, 10)}${res.letter}`;
      }

      const qaAttrFirstSeat = index === 0 ? QA_ATTRIBUTES.train.current.seatSelection.firstFreeSeat : '';

      return (
        <Text key={ index } className={ styles.item } qaAttr={ qaAttrFirstSeat }>
          { placeHtml }
        </Text>
      );
    });

    const renderDefault = () => {
      const sumSeats = (Object.keys(seats) as Array<keyof ISeats>).reduce((acc, key) => acc + Number(seats[key]), 0);

      return LABELS.SEATS_WITHOUT_CHOSE(sumSeats);
    };

    const getDownSeats = (places: string[]) => places.filter((_item: string, key: number) => key % 2 === 0);

    const placesHtml = placesPrices.map((placesPrice, i) => {
      const { stores: { trainSearchStore: { travellers }, trainStore: { car } } } = this.props;
      const { trainType, PlacesPrices, BuyTwo } = car;

      const buyFull = PlacesPrices.some(({ BuyFull }: { BuyFull: boolean }) => BuyFull);
      const buyFullWithoutScheme = !trainType && buyFull && BuyTwo;

      const currentPlaces = buyFullWithoutScheme ? getDownSeats(placesPrice.Places) : placesPrice.Places;
      const placesPriceHtml = placesPrice.Places.length === 1 && !placesPrice.Places[0]
        ? renderDefault()
        : renderMap(currentPlaces);

      const total = buyFull && BuyTwo ? PlacesPrices[0].BuyFullPrices[travellers] : placesPrice.PriceDetails.Total;

      return (
        <div className={ styles.numbers } key={ i }>
          { placesPriceHtml }
          <Text>
            { LABELS.DASH } { MoneyFormat.money(total, true) }
          </Text>
        </div>
      );
    });

    const placesDescription = letters.map(({ letter, description }, ind) => (
      <Text className={ styles.item } key={ ind }>
        { `${letter} ${LABELS.DASH} ${description}` }
      </Text>
    ));

    return (
      <div className={ styles.seats }>
        <div className={ styles.description }>
          { placesDescription }
        </div>
        { placesHtml }
      </div>
    );
  };

  renderCompartment = () => {
    const { oneCompartment } = this.state;
    const { trainsService, stores: { trainStore: { car } } } = this.props;

    let contentHtml = null;

    if (trainsService.hasReservedSeats(car)) {
      contentHtml = (
        <Select
          className={ styles.compartment }
          items={ trainsService.getCompartmentValue() as ItemType[] }
          onChange={ v => this.handleChangeValue('oneCompartment', v) }
          value={ oneCompartment as string | number }
        />
      );
    } else if (trainsService.hasCompartment(car)) {
      contentHtml = (
        <Checkbox
          className={ styles.compartment }
          value={ !!(oneCompartment !== null && oneCompartment === 0) }
          onChange={ v => this.handleChangeValue('oneCompartment', v ? 0 : null) }
        >
          { LABELS.IN_ONE_COMPARTMENT }
        </Checkbox>
      );
    }

    return contentHtml && (
      <div className={ styles.info }>
        <Text className={ styles.label }>
          { LABELS.COORDINATES_OF_SEATS }
        </Text>
        <div className={ styles.content }>
          { contentHtml }
        </div>
      </div>
    );
  };

  renderForOne = () => {
    const { range, down } = this.state;
    const { trainsService, stores: { trainStore: { car } } } = this.props;
    const { trainType, PlacesPrices, BuyTwo } = car;

    const buyFull = PlacesPrices.some(({ BuyFull }: { BuyFull: boolean }) => BuyFull);
    const buyFullWithoutScheme = !trainType && buyFull && BuyTwo;

    const checkPlaces = PlacesPrices[0].Places[0].length;
    const carHasChooseWindowSeats = trainsService.hasChooseWindowSeats(car);
    const carNotChooseSeats = trainsService.hasNotChooseSeats(car);

    const showSeatsOptionsHtml = !carHasChooseWindowSeats && !carNotChooseSeats && !buyFullWithoutScheme;

    const seatsOptionsHtml = showSeatsOptionsHtml && (
      <Checkbox
        className={ styles.checkbox }
        value={ !!down }
        onChange={ v => this.handleChangeValue('down', v ? 1 : '') }
      >
        { LABELS.DOWN_SEAT }
      </Checkbox>
    );

    return checkPlaces > 0 && (
      <div className={ styles.info }>
        <Text
          className={ styles.label }
          color='gray'
        >
          { LABELS.SEAT_SELECTION }
        </Text>
        <Input
          className={ styles.input }
          value={ range.from }
          onChange={ value => this.handleChangeSeats('from', value) }
          qaAttr={ QA_ATTRIBUTES.train.current.seatSelection.input }
        />
        { seatsOptionsHtml }
      </div>
    );
  };

  renderForMany = () => {
    const { down } = this.state;
    const { trainsService, stores: { trainSearchStore: { travellers }, trainStore: { car } } } = this.props;
    const { trainType, PlacesPrices, BuyTwo } = car;

    const buyFull = PlacesPrices.some(({ BuyFull }: { BuyFull: boolean }) => BuyFull);
    const buyFullWithoutScheme = !trainType && buyFull && BuyTwo;

    const seats = car.Seats;
    let downSeatsHtml = null;
    let undefSeatsHtml = null;

    if (trainsService.hasChooseUpDownSeats(car)) {
      const downFreeSeats = parseInt(seats.Down as string, 10);

      if (downFreeSeats) {
        const items: ItemType[] = [{ label: LABELS.DOES_NOT_HAVE, value: '' }];
        const maxSeats = travellers < downFreeSeats ? travellers : downFreeSeats;

        for (let i = 1; i <= maxSeats; i++) {
          items.push({ label: i.toString(), value: i });
        }

        downSeatsHtml = (
          <div className={ styles.info }>
            <Text
              className={ styles.label }
              color='gray'
            >
              { LABELS.COUNT_OF_DOWN_SEATS }
            </Text>
            <Select
              items={ items }
              onChange={ v => this.handleChangeValue('down', v) }
              value={ down as string | number }
            />
          </div>
        );
      }
    }

    const undefFreeSeats = parseInt(seats.Undef as string, 10);

    if (trainsService.hasChooseWindowSeats(car) && undefFreeSeats) {
      const items: ItemType[] = [{ label: LABELS.DOES_NOT_HAVE, value: '' }];
      const maxSeats = travellers < undefFreeSeats ? travellers : undefFreeSeats;

      for (let i = 1; i <= maxSeats; i++) {
        items.push({ label: i.toString(), value: i });
      }

      undefSeatsHtml = (
        <div className={ styles.info }>
          <Text
            className={ styles.label }
            color='gray'
          >
            { LABELS.COUNT_OF_SEATS_AROUND_WINDOW }
          </Text>
          <Select
            theme='default-border'
            items={ items }
            onChange={ v => this.handleChangeValue('down', v) }
            value={ down as string | number }
          />
        </div>
      );
    }

    const selectPlaces = () => {
      const { range: { from, to } } = this.state;

      return (
        <div className={ styles.info }>
          <Text
            color='gray'
            className={ styles.label }
          >
            { LABELS.SEATS_SELECTION }
          </Text>
          <div className={ styles.content }>
            <Input
              className={ styles.input }
              value={ from }
              onChange={ value => this.handleChangeSeats('from', value) }
            />
            &nbsp;{ LABELS.DASH }&nbsp;
            <Input
              className={ styles.input }
              value={ to }
              onChange={ value => this.handleChangeSeats('to', value) }
            />
          </div>
        </div>
      );
    };

    return (
      <>
        { selectPlaces() }
        { !buyFullWithoutScheme && downSeatsHtml }
        { undefSeatsHtml }
        { this.renderCompartment() }
      </>
    );
  };

  renderPlaceData = () => (
    this.props.stores.trainSearchStore.travellers === 1 ? this.renderForOne() : this.renderForMany()
  );

  renderHeader = (placesByTP: PlacesPricesItem[], unavailableTravelPolicy: boolean) => {
    const { stores: { trainStore: { car: { Number, HavePetsPlaces } } } } = this.props;
    const { accountTravelPolicy, travelPolicyList } = this.state;

    const buyTripPersonal = unavailableTravelPolicy && !!accountTravelPolicy;

    const havePetsPlaces = HavePetsPlaces && (<Text color={ 'gray' } type={ 'normal_14' }> { LABELS.HAVE_PETS_PLACES } </Text>);

    const travelPolicyWarning = !!placesByTP.length && (
      <NameErrorsTravelPolicy
        className={ styles.tp_warning }
        applyTP
        item={ placesByTP[0] }
        oneTravelPolicy={ buyTripPersonal }
        travelPolicyList={ travelPolicyList }
        selectedTravelPolicy={ buyTripPersonal ? accountTravelPolicy.Id : '' }
      />
    );

    return (
      <div className={ styles.main }>
        <div className={ styles.header }>
          <Text type='NORMAL_18'>
            { LABELS.CAR_NUMBER } { Number }
            { havePetsPlaces }
          </Text>
          { travelPolicyWarning }
        </div>
        { this.renderToNoteButton(placesByTP) }
      </div>
    );
  };

  renderTravelPolicyDialog = (placesByTP: PlacesPricesItem[], unavailableTravelPolicy: boolean) => {
    const { accountTravelPolicy, showTravelPolicyDialog } = this.state;
    const {
      stores: {
        trainSearchStore: { dateBack },
        trainSavedTicketStore: { isChoosingBackTrain },
        trainSavedTicketsStore: { isTicketWithTransfer },
      },
    } = this.props;

    if (!showTravelPolicyDialog) return null;

    const buttonText = (dateBack && !isChoosingBackTrain) || isTicketWithTransfer ? LABELS.DISRUPTED_TP_CONTINUE : BUTTON_LABELS.TO_CART;

    let errors = placesByTP[0].TravelPolicy.Errors;

    if (unavailableTravelPolicy && !!accountTravelPolicy) {
      errors = {
        [accountTravelPolicy.Id]: placesByTP[0].TravelPolicy.Errors[accountTravelPolicy.Id],
      };
    }

    return (
      <TravelPolicyDialog
        show={ showTravelPolicyDialog }
        errors={ errors }
        onClose={ () => this.toggleTravelPolicyDialog(false) }
        onAdd={ this.handleAddToCart }
        label={ buttonText }
      />
    );
  };

  renderButton = (
    placesByTP: PlacesPricesItem[],
    selectedPlaceValidationResult: boolean,
    label: string,
    unavailableTravelPolicy: boolean,
  ) => {
    const {
      stores: {
        trainStore: {
          car: {
            Places,
          },
        },
        trainSearchStore: {
          travellers,
        },
      },
    } = this.props;
    const {
      disableButtonOnRequest,
      selectedPlace,
      disableToCartIfTPApply,
      rightsBuyTrip: {
        BuyTripPersonal,
        BuyTripAccount,
      },
      range: {
        from,
        to,
      },
      accountTravelPolicy,
      entireCompartment,
    } = this.state;

    const travellersCoincidesWithPlaces = !entireCompartment
      ? selectedPlace.length === travellers || (travellers > 1 ? !!to : !!from) : true;

    const placesPricesCommonCar = Places.length === 1 && !Places[0];
    const rulesForCommonCar = placesPricesCommonCar && !placesByTP.length;
    const tpRights = (BuyTripPersonal === BUYTRIPSPERSONALRIGHT.TravelPolicy || BUYTRIPSPERSONALRIGHT.ApprovalScheme) &&
      BuyTripAccount === BUYTRIPSACCOUNTRIGHT.Unavailable;

    const valuesErrorTp =
      placesByTP.length && accountTravelPolicy ?
        Object.entries(placesByTP[0].TravelPolicy.Errors)
          .find(k => k[0] === accountTravelPolicy.Id && (k[1] as string[]).includes(` ${LABELS.MORE_EXPENSIVE} `))
        : [];

    const disabledRuleTp = placesByTP.length > 0 && unavailableTravelPolicy && disableToCartIfTPApply
      && (tpRights && !!valuesErrorTp);

    const disabledRules = disableToCartIfTPApply && !(selectedPlace.length || !!from || rulesForCommonCar) && tpRights;

    const startNumber = from && !!from.trim().match(STARTS_WITH_NUMBER);

    const itChosenPlace = selectedPlace.length > 0 || startNumber;

    const textHtml = disabledRuleTp ? LABELS.NO_RIGHTS_BUY : LABELS.NO_PLACES_SELECTED;
    const chooseClassName = disabledRuleTp ? styles.tooltip_tp : styles.tooltip;

    const isShowElem = !!selectedPlaceValidationResult
      || disabledRules
      || disabledRuleTp
      || !itChosenPlace
      || disableButtonOnRequest
      || !travellersCoincidesWithPlaces;

    return (
      <Tooltip
        show={ isShowElem }
        renderContent={ () => (
          <Text
            className={ chooseClassName }
            color='white'
            type='NORMAL_14_130'
          >
            { textHtml }
          </Text>
        ) }
      >
        <Button
          type='secondary'
          disabled={ isShowElem }
          onClick={ () => this.handleAddToCartClick(placesByTP) }
          qaAttr={ QA_ATTRIBUTES.train.current.buttonCart }
        >
          { label }
        </Button>
      </Tooltip>
    );
  };

  renderConfirmButton = (
    placesByTP: PlacesPricesItem[],
    selectedPlaceValidationResult: boolean,
    unavailableTravelPolicy: boolean,
  ) => {
    const {
      stores: {
        trainSearchStore: { dateBack },
        trainSavedTicketStore: { isChoosingBackTrain },
        trainSavedTicketsStore: { isTicketWithTransfer, buttonLabel },
      },
    } = this.props;

    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.SEARCH.SEARCH_RESULTS_ADD_CART_BUTTON_PRESSED(ANALYTIC_SERVICE_TYPES.TRAIN),
    );

    if (isTicketWithTransfer) {
      return this.renderButton(placesByTP, selectedPlaceValidationResult, buttonLabel, unavailableTravelPolicy);
    }

    const buttonText = dateBack && !isChoosingBackTrain ? BUTTON_LABELS.SEARCH_BACK_TICKETS : BUTTON_LABELS.TO_CART;

    return this.renderButton(placesByTP, selectedPlaceValidationResult, buttonText, unavailableTravelPolicy);
  };

  renderToNoteButton = (placesByTP: PlacesPricesItem[]) => {
    const { stores: { trainStore: { car: { PlacesPrices, trainType } } } } = this.props;
    const { range, selectedPlace, disableToCartIfTPApply, rightsBuyTrip: { BuyTripPersonal, BuyTripAccount } } = this.state;
    const tpRights = (BuyTripPersonal === BUYTRIPSPERSONALRIGHT.TravelPolicy || BuyTripPersonal === BUYTRIPSPERSONALRIGHT.ApprovalScheme) && BuyTripAccount === BUYTRIPSACCOUNTRIGHT.Unavailable;
    const disabledRules = !!placesByTP.length && disableToCartIfTPApply && tpRights;

    const buyFull = PlacesPrices.some(({ BuyFull }: { BuyFull: boolean }) => BuyFull);
    const withoutSchemaIsBuyFull = buyFull && !trainType;

    const toNoteOptions = !range.from && !selectedPlace.length && !withoutSchemaIsBuyFull;

    return (
      <Tooltip
        show={ toNoteOptions }
        renderContent={ () => (
          <Text
            className={ styles.tooltip }
            type='NORMAL_14_130'
            color='white'
          >
            { LABELS.NO_PLACES_SELECTED }
          </Text>
        ) }
      >
        <ToNote
          disabled={ toNoteOptions || disabledRules }
          label={ LABELS.TO_NOTE }
          onClick={ this.handleAddToNote }
          qaAttr={ QA_ATTRIBUTES.train.current.note }
        />
      </Tooltip>
    );
  };

  renderEntireCompartment = () => {
    const {
      trainsService,
      stores: {
        trainStore: {
          train,
          car: { PlacesByCompartmentPrices },
        },
        trainSearchStore: {
          travellers,
        },
      },
    } = this.props;
    const {
      selectedPlace,
      entireCompartment,
      initialSelectedPlace,
    } = this.state;

    const isSapsan = trainsService.isSapsan(train);
    const isLastochka = trainsService.isLastochka(train);
    const differentTrains = isSapsan || isLastochka;

    return (
      <EntireCompartment
        travellers={ travellers }
        selectedPlace={ selectedPlace }
        entireCompartment={ entireCompartment }
        initialSelectedPlace={ initialSelectedPlace }
        differentTrains={ differentTrains }
        placesByCompartmentPrices={ PlacesByCompartmentPrices }
        setEntireCompartment={ this.setEntireCompartment }
      />
    );
  };

  renderChooseRate = () => {
    const { selectedPlace, refundable } = this.state;

    const allItemsHasAlternative = selectedPlace.length && selectedPlace.every(({ hasAlternative }) => hasAlternative);

    return !!allItemsHasAlternative && (
      <Checkbox
        className={ styles.checkbox }
        value={ refundable }
        onChange={ (value) => this.setState({ refundable: value }) }
      >
        <Tooltip
          className={ styles.tip }
          renderContent={ () => (
            <div className={ styles.tip_content }>
              <Text
                type='NORMAL_14_130'
                color='white'
              >
                { LABELS.CHOOSE_RATE.TOOLTIP }
              </Text>
            </div>
          ) }
        >
          <div className={ styles.label }>
            <Text type='NORMAL_14'>{ LABELS.CHOOSE_RATE.MAIN }</Text>
            <Icon type='question' className={ styles.icon }/>
          </div>
        </Tooltip>
      </Checkbox>
    );
  };

  renderTrainScheme = () => {
    const { selectedPlace, entireCompartment } = this.state;
    const {
      trainsService,
      stores: {
        trainSearchStore: { travellers },
        trainStore: {
          train,
          car,
          car: {
            Number,
            PlacesPrices,
            carJSON,
            carImage,
            direction,
            PlacesByCompartmentPrices = [],
          },
        },
      },
      appService,
    } = this.props;

    const isSapsan = trainsService.isSapsan(train);
    const isLastochka = trainsService.isLastochka(train);
    const differentTrains = isSapsan || isLastochka;

    const isTwoFloors = trainsService.hasTwoFloors(train, car);

    const freePlaces =
      PlacesPrices.map(({
        PriceDetails: { Total, AgentFee },
        AltPriceDetails: {
          Total: altTotal,
          AgentFee: altAgentFee,
        } = {},
        HasAlternative,
        Places,
        NonRefundable,
        BuyFull,
        BuyFullPrices,
        BuyFullAltPrices,
        PlacesInfo,
      }) => ({
        places: Places,
        nonRefundable: NonRefundable,
        hasAlternative: HasAlternative,
        buyFull: BuyFull,
        buyFullPrices: BuyFullPrices,
        buyFullAltPrices: BuyFullAltPrices,
        price: Total,
        altPrice: altTotal,
        placesInfo: PlacesInfo,
        agentFee: AgentFee,
        altFee: altAgentFee,
        travellers,
      }));

    const compartmentSections = PlacesByCompartmentPrices.map(({
      BuyFullPriceDetails: { Total },
      BuyFullAltPriceDetails: { Total: altTotal },
      PlacesByCompartment: { CompartmentNumber, Places },
      BuyFull,
      SpecialTariff,
    }) => {
      const updatedPlaces = Places.map(place =>
        parseInt(place
          .replace(DIGITSANDPLUS, ''), 10)
          .toString());

      const hasAlternative = altTotal && altTotal !== Total;

      return {
        compartmentPlaces: updatedPlaces,
        compartmentPrice: Total,
        compartmentAltPrice: altTotal,
        buyFullCompartment: BuyFull,
        CompartmentNumber,
        SpecialTariff,
        hasAlternative,
      };
    });

    return (
      <TrainCar
        appService={ appService }
        number={ Number }
        carJSON={ carJSON }
        carImage={ carImage }
        twoFloors={ isTwoFloors }
        freePlaces={ freePlaces as IFreePlace[] }
        compartmentSections={ compartmentSections as ICompartSection[] }
        selectedPlaces={ selectedPlace }
        handleSelectedPlace={ this.handleSelectedPlace }
        choiceInitiaPlace={ this.choiceInitiaPlace }
        isSapsanFamily
        differentTrains={ differentTrains }
        entireCompartment={ entireCompartment }
        handleSelectedSection={ this.handleSelectedSection }
      >
        { direction && (
          <TrainCarDirection
            direction={ direction }
          />
        ) }
      </TrainCar>
    );
  };

  renderMeetingRoom = () => {
    const { stores: { trainStore: { car: { PlacesPrices } }, trainSearchStore: { travellers: travellersCount } } } = this.props;

    const travellers = toDecline(travellersCount, LABELS.TRAVELLERS_DECLINES);
    const buyFullPrices = PlacesPrices[0].BuyFullPrices;
    const price = buyFullPrices[travellersCount];

    return (
      <div className={ styles.not_standard }>
        <div className={ styles.info }>
          <Text type='NORMAL_16'>
            {LABELS.MEETING_ROOM_BUY_FULL_PLACES(travellersCount, travellers, price)}
          </Text>
        </div>
      </div>
    );
  };

  renderDescriptionBuyFullPlaces = () => {
    const { trainsService, stores: { trainStore: { car, train } } } = this.props;
    const { TypeShow, ClassService, trainType, PlacesPrices, BuyTwo } = car;

    const buyFull = PlacesPrices.some(({ BuyFull }: { BuyFull: boolean }) => BuyFull);
    const buyFullWithoutScheme = !trainType && buyFull && BuyTwo;

    if (!buyFullWithoutScheme) return null;

    const isSapsan = trainsService.isSapsan(train);
    const type = isSapsan ? trainsService.getSapsanType(ClassService) : TypeShow;

    return (
      <div className={ styles.info }>
        <Text type='NORMAL_16'>
          {LABELS.ROOM_WITHOUT_SCHEMA_BUY_FULL_PLACES(type)}
        </Text>
      </div>
    );
  };

  renderTrainHtml = () => {
    const { stores: { trainStore: { car } } } = this.props;
    const { trainType, PlacesPrices, Seats, BuyTwo } = car;

    const buyFull = PlacesPrices.some(({ BuyFull }: { BuyFull: boolean }) => BuyFull);

    if (!trainType && buyFull && !BuyTwo) {
      return this.renderMeetingRoom();
    }

    if (trainType) {
      return this.renderTrainScheme();
    }

    return (
      <div className={ styles.not_standard }>
        { this.renderDescriptionBuyFullPlaces() }
        <div className={ styles.info }>
          <Text
            className={ `${styles.label} ${styles.to_top}` }
            color='gray'
          >
            { LABELS.FREE_SEATS }
          </Text>
          { this.renderFreeSeats(PlacesPrices, Seats) }
        </div>
        { this.renderPlaceData() }
      </div>
    );
  };

  renderLoading = () => (
    <div className={ styles.loading }>
      <PageLoader text={ LABELS.SEARCH } />
    </div>
  );

  render() {
    const {
      animatedClass,
      selectedPlace,
      showAddToCartDialog,
      rightsBuyTrip,
      range,
    } = this.state;

    const {
      trainsService,
      stores: {
        trainStore: {
          train,
          train: { ProviderName },
          car,
          loadingCarDetails,
        },
      },
    } = this.props;

    if (loadingCarDetails) return this.renderLoading();

    const { TypeShow, ClassService, description, Places, PlacesPrices, trainType, BuyTwo } = car;

    const unavailableTravelPolicy = rightsBuyTrip.BuyTripAccount === BUYTRIPSACCOUNTRIGHT.Unavailable &&
      rightsBuyTrip.BuyTripPersonal !== BUYTRIPSPERSONALRIGHT.Unavailable;
    const isSapsan = trainsService.isSapsan(train);
    const type = isSapsan ? trainsService.getSapsanType(ClassService) : TypeShow;
    const typeAndClass = `${type} ${ClassService ? `(${ClassService})` : ''}`;
    const isSapsanFamily = isSapsan && ClassService === '2Ю';
    const familyLabel = isSapsanFamily ? LABELS.FAMILY_CAR : '';

    const placesByTP = this.handleGetPlacesByTP(unavailableTravelPolicy);
    const totalPrice = this.handleCountTotalPrice();
    const totalFee = this.handleCountTotalFee();
    const buyFull = PlacesPrices.some(({ BuyFull }) => BuyFull);
    const withoutSchemaIsBuyFull = buyFull && !trainType;
    const selectedPlaces = withoutSchemaIsBuyFull
      ? Places.map(item => parseInt(item, 10)).join(', ')
      : selectedPlace.map(({ number }) => number).join(', ');

    const selectedPlaceValidationResult = buyFull && trainType && !selectedPlace.length;

    const classAndDescHtml = (
      <div className={ styles.details }>
        <Text
          type='NORMAL_16'
          className={ styles.type }
        >
          { typeAndClass }
        </Text>
        <Text type='NORMAL_16'>
          { description || '' }
        </Text>
        <Text type='NORMAL_16'>
          { familyLabel || '' }
        </Text>
      </div>
    );

    const selectedSeatsHtml = (!!selectedPlace.length || (withoutSchemaIsBuyFull && !BuyTwo)) && (
      <Text
        className={ styles.selected_seats }
        type='NORMAL_16'
        qaAttr={ QA_ATTRIBUTES.train.current.chosenSeats }
      >
        {LABELS.CHOSEN_PLACES} {selectedPlaces}
      </Text>
    );

    const { agentMode } = this.props.appService.get();

    const renderAgentFee = () => {
      if (!isSmartAgent) {
        return null;
      }

      return (
        <AgencyFee
          fee={ totalFee as number }
          agentMode={ agentMode }
        />
      );
    };

    const renderTotalPrice = () => {
      if (!totalPrice) {
        return null;
      }

      const preparedTotalPrice = totalPrice && isSmartAgent && !agentMode
        ? totalPrice + (totalFee as number)
        : totalPrice;

      return (
        <div className={ styles.price } data-qa={ QA_ATTRIBUTES.train.current.price }>
          <Text
            type='bold_20'
          >
            { MoneyFormat.moneyWithDecimal(preparedTotalPrice) }
          </Text>
          <Text className={ styles.currency }>
            { LABELS.RUB }
          </Text>
        </div>
      );
    };

    const addToCartDialogHtml = (
      <AddToCartDialog
        showAddToCartDialog={ showAddToCartDialog }
        selectedPlace={ selectedPlace }
        car={ car }
        range={ range }
        onClose={ () => this.toggleAddToCartDialog(false) }
        onAddToCart={ this.handleAddToCartInDialog }
      />
    );

    return (
      <ItemPanel
        animationClass={ animatedClass }
        className={ styles.item_panel }
        warning={ !!placesByTP.length }
        onAnimationEnd={ this.handleAnimationEnd }
        renderHeader={ () => this.renderHeader(placesByTP, unavailableTravelPolicy) }
      >
        <div className={ styles.wrapper }>
          <div className={ styles.content }>
            { classAndDescHtml }
            { this.renderTrainHtml() }
            <InfoProviderIM providerName={ ProviderName } className={ styles.provider } />
          </div>
          <div className={ styles.actions }>
            { selectedSeatsHtml }
            { this.renderChooseRate() }
            { this.renderEntireCompartment() }
            <div className={ styles.action }>
              { renderTotalPrice() }
              { this.renderConfirmButton(placesByTP, selectedPlaceValidationResult as boolean, unavailableTravelPolicy) }
              { renderAgentFee() }
            </div>
          </div>
        </div>
        { addToCartDialogHtml }
        { this.renderTravelPolicyDialog(placesByTP, unavailableTravelPolicy) }
      </ItemPanel>
    );
  }
}

export { TrainCarPage };
